import React, { useEffect, useState, useCallback, useRef } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { Link, useNavigate } from 'react-router-dom'
import PORTNUMBER from '../../../../api/api'
import axios from "axios"
import { MdDelete } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import IMAGE_HOST from '../../../../api/api'
import { useForm } from 'react-hook-form';

// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { loginModalTrue } from '../../../../redux/actions/action'
import { useDispatch, useSelector } from 'react-redux'
import { useMatrimonial } from '../../../../hooks/matrimonial/useMatrimonial'
import { RiUserSearchFill } from 'react-icons/ri'
import useDeleteMatrimonial from '../../../../hooks/matrimonial/useDeleteMatrimonial'

const MarriageList = () => {
    const { t } = useTranslation();
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const navigate = useNavigate()
    const UserActiveList = useSelector((state) => state.userLoginReducer)

    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const dispatch = useDispatch()
    const UserActive = useSelector((state) => state.userLoginReducer)
    const [userId, setUserId] = useState();
    const [applicantId, setApplicantId] = useState();

    const [userIdList, setUserIdList] = useState();


    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);


    const [applicantDatanew, setApplicantData] = useState(["not"])

console.log("applicantDatanew", applicantDatanew)
    const [statusId, setStatusId] = useState();
    const [activeShow, setActiveShow] = useState(false);
    const [marriageToggle, setMarriageToggle] = useState(true);

    const PORT = PORTNUMBER.PORTNUMBER

    const { data: applicantData, isLoading, isError, error, refetch, fetchNextPage, isFetchingNextPage, hasNextPage } = useMatrimonial(filterData);
    const { mutate: deleteMatrimonial } = useDeleteMatrimonial(PORT, TOKEN);


    const { register, handleSubmit } = useForm();

    useEffect(() => {
        // window.scrollTo(0, 600)
        refetch()
    }, [refetch])



    const LoadMarrigeSelf = useCallback(async () => {
        setMarriageToggle(false)
        setLoading(false)
        await axios.get(`${PORT}/user/marriage/list`, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setLoading(true)

                setApplicantData(res.data.data.marriageList)

            })
    }, [PORT, TOKEN])



    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const openSelfList = useCallback((user) => {
        setUserIdList(user)
        if (TOKEN || UserActiveList) {
            LoadMarrigeSelf()
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [LoadMarrigeSelf, TOKEN, UserActiveList, dispatch])


    const deleteApplication = async () => {
        deleteMatrimonial({ statusId }, {
            onSuccess: () => {
                refetch()
                activeHandleClose();
            },
        });
    }


    const onSubmit = async (data) => {
        setFilterData(data)
    }

    const openMarriageForm = useCallback((user) => {
        setUserId(user)
        if (TOKEN || UserActive) {
            navigate("marriageForm")
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [TOKEN, UserActive, dispatch, navigate])




    useEffect(() => {
        if (UserActive === true && userId) {
            navigate("marriageForm")
        }
    }, [UserActive, navigate, userId])


    const viewApplicant = useCallback((user) => {
        setApplicantId(user)
        if (TOKEN || UserActive) {
            navigate(`/viewApplicant/${user}`)
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [UserActive])




    useEffect(() => {
        if (UserActive === true && applicantId) {
            navigate(`/viewApplicant/${applicantId}`)
        }
    }, [UserActive])



    const observerRef = useRef(); // Reference for the observer


    // Intersection Observer callback
    const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage(); // Fetch the next page when the observer is triggered
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 1.0, // Fully visible before triggering
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasNextPage]); // Re-run when `hasNextPage` changes

    if (isLoading) {
        return <div className="loader-wrapper absolute-sec">
            <div className="loader">

                <div className="loading-svg">
                    <RiUserSearchFill />

                </div>

            </div>

        </div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>Koli Matrimonial - Find Your Perfect Life Partner</title>
                <meta name="description" content="Welcome to Koli Matrimonial by Koli Samaj Vikash Sanstha! Our platform offers a new approach to finding Koli marriage relationships, allowing you to easily find a life partner for yourself or your family from the comfort of your home. Register now to start your journey!" />
                <meta name="keywords" content="Koli Matrimonial, Koli Samaj Saadi, Koli Samaj Vivah, Koli Samaj Sammelan, Koli Samaj Marriage, Koli Samaj Riste, Koli Samaj Matrimonial Website, Koli Samaj Boys, Koli Samaj Brides" />
                <meta name="author" content="Mahendra Koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/matrimonial" />
            </Helmet>
            <section className="bg-banner our-marrige" style={{ backgroundImage: `url(${ImageObj.Marriage})` }}>
                <div className="container">
                    <div className="couple-head">
                        <h2>{t('Matrimonial.Banner')}</h2>
                    </div>
                    <div className="marriage-filter shadow">
                        <div className="tab-head">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <select name="gender" {...register('gender', { required: false })} className="form-control form-select" id="validationCustomThree11">
                                                <option value="" className="disabled-value d-none">{t('Member.Gender')}</option>
                                                <option value="male">{t('Member.Male')}</option>
                                                <option value="female">{t('Member.Female')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Select Your Gender.
                                            </div>
                                        </div>
                                    </div>

                                    {/* Age From Input */}
                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                {...register('ageFrom', { required: false })}
                                                className="form-control"
                                                placeholder="Age From"
                                            />
                                        </div>
                                    </div>

                                    {/* Age To Input */}
                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                {...register('ageTo', { required: false })}
                                                className="form-control"
                                                placeholder="Age To"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                {...register('city', { required: false })}
                                                className="form-control"
                                                placeholder="City"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                {...register('state', { required: false })}
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="State"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 col-sm-12">
                                        <div className="theme-btn btn-group-right text-right">
                                            <button type="submit" className="btn">Search</button>
                                            <button type="reset" onClick={() => refetch()} className="btn">Reset</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>



                </div>
                <div className="dark">

                </div>
            </section>
            <section className="members pt-100 pb-100">
                <div className="container">
                    <div className=" brown-button menu-btn languages justify-content-start mb-sm-5 mb-2 ">
                        {
                            TOKEN || UserActive ?
                                <Link to="marriageForm" className="btn">Register</Link> :
                                <button onClick={() => openMarriageForm("id")} className="btn ">Login before register</button>
                        }
                        {/* {

                            marriageToggle === true ?
                                <button onClick={() => openSelfList("id")} className="btn ">Your List</button> :
                                <button onClick={() => refetch()} className="btn ">All List</button>

                        } */}



                    </div>
                    <div className="col-12">
                        <div className="row">


                            {
                                applicantData.pages.map((page, pageIndex) => (
                                    <React.Fragment key={pageIndex}>
                                        {

                                            !isLoading ? applicantData ? page.data.map((applicant, i) => (
                                                <div className="col-sm-4 col-lg-3" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                                    <div className="couple-card-box">

                                                        <div className="card-box ">
                                                            <div className="box-img ">
                                                                <img aria-hidden="true" src={`${HOST_PORT}/${applicant.image}`} alt="" ></img>
                                                                {
                                                                    (UserTokenId === applicant?.user?._id) ?
                                                                        <div className="social ">
                                                                            <nav>
                                                                                <span className="facebook anchore" onClick={() => activeHandleShow(applicant?._id)}><MdDelete />हटाये</span>
                                                                                <Link to={`/MarriageDataEdit/${applicant?._id}`} className="twitter anchore" ><FaRegEdit />बदले</Link>
                                                                            </nav>
                                                                        </div> : ""
                                                                }

                                                            </div>
                                                            <div className="card-content ">
                                                                <ul>
                                                                    <li>{t('Marriage_Data.PersonalDetails.FullName')} </li>
                                                                    <li>{applicant.full_name}</li>
                                                                    <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} </li>
                                                                    <li>{applicant.marital_status}</li>
                                                                    <li>{t('Marriage_Data.PersonalDetails.state')} </li>
                                                                    <li>{applicant.state}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu')} </li>
                                                                    <li>{applicant.education_level}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu3')} </li>
                                                                    <li>{applicant.occupation}</li>

                                                                </ul>

                                                            </div>

                                                            <div className="brown-button">
                                                                {
                                                                    TOKEN || UserActive ?
                                                                        <Link className="btn w-100" to={`/viewApplicant/${applicant?._id}`} >और देखे</Link> :
                                                                        <button className="btn w-100" onClick={() => viewApplicant(applicant?._id)} >और देखे</button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )) : <span className="no-data">No Mrriage Applicant Found</span>
                                                : <div className="loader-wrapper absolute-sec">
                                                    <div className="loader">

                                                        <div className="loading-svg">
                                                            <RiUserSearchFill />

                                                        </div>

                                                    </div>

                                                </div>

                                        }

                                    </React.Fragment>
                                ))
                            }

                            {hasNextPage && <div ref={observerRef}>
                                {hasNextPage && isFetchingNextPage && <div className="spinner">
                                    <div className="rect1"></div>
                                    <div className="rect2"></div>
                                    <div className="rect3"></div>
                                    <div className="rect4"></div>
                                    <div className="rect5"></div>
                                </div>}
                            </div>}
                        </div>
                    </div>
                </div>
                {/* delete application modal */}
                <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                    <Modal.Body>Are you sure you want to delete this image ?</Modal.Body>
                    <Modal.Footer>
                        <div className="theme-btn btn-group-right text-right">
                            <Button variant="secondary"
                                onClick={deleteApplication}
                            >
                                Yes
                            </Button>
                            <Button variant="primary" onClick={activeHandleClose}>
                                No
                            </Button>
                        </div>

                    </Modal.Footer>
                </Modal>
                {/*  delete application modal  */}
            </section>
        </React.Fragment>
    )
}

export default MarriageList;