import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchCongratsProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/congrats/profile`,
        { congratsId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const useCongratsProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['congratsProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchCongratsProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useCongratsProfile;
