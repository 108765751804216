import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchPostById = async ({ queryKey }) => {
    const [, { PORT, postIdEdit , TOKEN }] = queryKey;
    const { data } = await axios.post(
        `${PORT}/user/post/detail`,
        { postId: postIdEdit  },
        { headers: { authorization: `Bearer ${TOKEN}` } }
    );
    return data.data;
};

const usePostById = ({ PORT, postIdEdit , TOKEN }) => {
    return useQuery({
        queryKey: ['postById', { PORT, postIdEdit , TOKEN }],
        queryFn: fetchPostById,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default usePostById;
