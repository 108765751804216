import React, { useEffect } from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const GoutamBuddha = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return (() => {
            document.body.classList.remove("choclate-bg");

        })
    }, [])


    return (
        <React.Fragment>
       <Helmet>
            <title>Gautama Buddha History - Life and Teachings of the Buddha</title>
            <meta name="description" content="Explore the life and teachings of Gautama Buddha, the founder of Buddhism. Learn about Siddhattha Gotama's journey, his enlightenment, and his impact on spiritual and philosophical traditions around the world." />
            <meta name="keywords" content="Gautama Buddha, Lord Buddha, Siddhattha Gotama, Buddha Shakyamuni, Buddhism, Buddhist teachings, life of Buddha, Buddha's enlightenment, Buddhist philosophy" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/goutamBuddha" />
        </Helmet>

        <div className="container padding-mob-0">
            <div className="history-show-top bg" style={{ backgroundImage: `url(${ImageObj.ShowHisBg2})` }}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('GoutamBuddh.titleOne')}</h2>
                        <p>{t('GoutamBuddh.summeryOne')}</p>
                        <p>{t('GoutamBuddh.summeryTwo')}</p>
                        <h2>{t('GoutamBuddh.titleTwo')}</h2>
                        <p>{t('GoutamBuddh.summeryThree')}</p>
                        <h2>{t('GoutamBuddh.titleThree')}</h2>
                        <p>{t('GoutamBuddh.summeryFour')}</p>
                        <h2>{t('GoutamBuddh.titleFour')}</h2>
                        <p>{t('GoutamBuddh.summeryFive')}</p>
                        <h2>{t('GoutamBuddh.titleFive')}</h2>
                        <p>{t('GoutamBuddh.summerySix')}</p>
                        <h2>{t('GoutamBuddh.titleSix')}</h2>
                        <p>{t('GoutamBuddh.summerySeven')}</p>
                        <h2>{t('GoutamBuddh.titleSeven')}</h2>
                        <p>{t('GoutamBuddh.summeryEight')}</p>
                        <h2>{t('GoutamBuddh.titleEight')}</h2>
                        <p>{t('GoutamBuddh.summeryNine')}</p>
                        <h2>{t('GoutamBuddh.titleNine')}</h2>
                        <p>{t('GoutamBuddh.summeryTen')}</p>
                        <div className="list">
                            <ul>
                                <li>{t('GoutamBuddh.summeryEleven')}</li>
                                <li>{t('GoutamBuddh.summeryTwelve')}</li>
                                <li>{t('GoutamBuddh.summeryThirteen')}</li>
                                <li>{t('GoutamBuddh.summeryFourteen')}</li>
                                <li>{t('GoutamBuddh.summeryFifteen')}</li>
                            </ul>
                        </div>
                        <h2>{t('GoutamBuddh.titleTen')}</h2>
                        <p>{t('GoutamBuddh.summerySixteen')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>

    )
}

export default GoutamBuddha;