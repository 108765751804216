
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import PORTNUMBER from '../../api/api'

// Function to fetch data with pagination
const fetchMatrimonial = async ({ pageParam = 1, queryKey }) => {


    const TOKEN = localStorage.getItem("token")
    const PORT = PORTNUMBER.PORTNUMBER

  // queryKey[1] will contain the customString if provided
  // const customString = queryKey[1];
  // Extract the custom string from queryKey (assuming it's the second element)
  const filter = queryKey[1];

  // Determine the endpoint based on whether filter exists and is non-empty
  const endpoint = filter && Object.values(filter).some(val => val) ? `${PORT}/user/marriage/filteredList` : `${PORT}/user/marriage/all`;
  
  // Construct the params object
  const params = { page: pageParam, limit: 100, ...filter };

  const response = await axios.post(endpoint, params, {
    headers: {
      authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json',
    },
  });
  
  return {
    data: response.data.data.marriageList,
    nextPage: pageParam + 1, // The next page to load
  };
};

// Custom hook using `useInfiniteQuery` for lazy loading
export const useMatrimonial = (customString) => {
  return useInfiniteQuery({
    queryFn: fetchMatrimonial ,
    getNextPageParam: (lastPage) => lastPage.nextPage, // Get the next page parameter
    // Optional configurations
    staleTime: 5000, // Data freshness duration
    cacheTime: 60000, // Cache time
    retry: 2, // Retry attempts
    queryKey: ['matrimonial', customString],
  });
};