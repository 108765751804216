import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const submitPostUpdate = async ({ data, imageFile, Username, postIdEdit, PORT, TOKEN, postById }) => {
    const fs = new FormData();
    fs.append("postId", postIdEdit);
    fs.append('title', Username);


    for (const key in data) {
        data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? postById.description : data[key];
        fs.append(key, data[key]);
    }
    

    if (imageFile) {
        fs.append("image", imageFile);
    }

    const response = await axios.post(`${PORT}/user/post/update`, fs, {
        headers: { authorization: `Bearer ${TOKEN}` }
    });

    return response.data;
};

const useSubmitPostUpdate = (PORT, TOKEN, postById) => {
    return useMutation({
        mutationFn: ({ data, imageFile, Username, postIdEdit }) => submitPostUpdate({ data, imageFile, Username, postIdEdit, PORT, TOKEN, postById }),
    });
};

export default useSubmitPostUpdate;
