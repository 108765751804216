import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const studentDelete = async ({ studentId, AdminToken, PORT_ADMIN }) => {
    const response = await axios.post(
        `${PORT_ADMIN}/topStudents/delete`,
        { studentId },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useStudentDelete = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ studentId, AdminToken, PORT_ADMIN }) => studentDelete({ studentId, AdminToken, PORT_ADMIN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['studentDelete']); // Adjust the query key if needed
        },
    });
};

export default useStudentDelete;
