import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const removeImage = async ({ statusId, id, AdminToken, PORT_ADMIN }) => {
  const response = await axios.post(
    `${PORT_ADMIN}/event/image/remove`,
    { imageName: statusId, eventId: id },
    { headers: { authorization: `Bearer ${AdminToken}` } }
  );
  return response.data;
};

const useRemoveImage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ statusId, id, AdminToken, PORT_ADMIN }) => removeImage({ statusId, id, AdminToken, PORT_ADMIN }),
    onSuccess: () => {
      queryClient.invalidateQueries(['eventImages']); // Adjust the query key if needed
    },
  });
};

export default useRemoveImage;
