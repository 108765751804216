
import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm } from 'react-hook-form';
import { GiSwordsEmblem } from 'react-icons/gi';


// icons
import { FaSearch } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import PORTNUMBER_ADMIN from '../../../../api/api'

// Import Datepicker
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'

// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import FormValidation from '../../../../formValidation/formValidation'
import useStudentsList from '../../../../hooks/students/useStudentsList';
import useStudentDelete from '../../../../hooks/students/useStudentDelete';
import useCreateStudent from '../../../../hooks/students/useCreateStudent';


const TopStudents = () => {
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")
    const { register, handleSubmit } = useForm();
    const FormValidationNew = FormValidation
    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const { mutate: createStudent } = useCreateStudent(PORT_ADMIN, AdminToken);
    const { data: allStudents, refetch, isLoading: isLoadingStudent, } = useStudentsList(PORT_ADMIN);
    const { mutate: studentDelete } = useStudentDelete();


    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const deleteStudent = async () => {
        studentDelete(
            { studentId: statusId, AdminToken, PORT_ADMIN },
            {
                onSuccess: (res) => {
                    refetch();
                    toast.success("Student is deleted successfully.")
                    activeHandleClose()
                },
                onError: (error) => {
                    toast.error("Failed to delete Student.");
                }
            }
        );
    }

    const onSubmit = (data) => {
        const imageFile = document.getElementById('studentImage').files[0];
        createStudent({ data, imageFile }, {
            onSuccess: () => {
                refetch()
                toast.success("You have successfully added a new student")
            }
        });
    }

    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');

        }

    }, [])


    useEffect(() => {
        FormValidationNew()
        refetch()
    }, [FormValidationNew])

    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Student</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/userManagement" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input id="studentImage" name="image" {...register('image', { required: false })} type="file" className="form-control" />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustom01"  {...register('full_name', { required: true })} placeholder=" Name" required />
                                        <div className="invalid-feedback">
                                            User name required.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomTwo2" {...register('father_name', { required: true })} placeholder="Father Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Father Name
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomThree2" {...register('class', { required: true })} placeholder="Class" required />
                                        <div className="invalid-feedback">
                                            Please enter class.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomFour2" {...register('percentage', { required: true })} placeholder="Percentage" required />
                                        <div className="invalid-feedback">
                                            Please enter percentage.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomFive2" {...register('school', { required: true })} placeholder="School Name" required />
                                        <div className="invalid-feedback">
                                            Please enter school name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSix2" {...register('address', { required: true })} placeholder="Address" required />
                                        <div className="invalid-feedback">
                                            Please enter adress.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSeven2" {...register('roll_num', { required: false })} placeholder="Roll Number" />
                                        <div className="invalid-feedback">
                                            Please enter roll number.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomEight2" {...register('studentId', { required: false })} placeholder="Student Year " />
                                        <div className="invalid-feedback">
                                            Please enter Year.
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/userManagement" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Class</th>
                                <th>School</th>
                                <th>Year</th>
                                <th>Roll Num</th>
                                <th>Percentage</th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>


                            {allStudents && allStudents.length ? allStudents.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.full_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else {
                                    return false
                                }


                            }).map(student => (
                                <tr key={student._id}>
                                    <td >
                                        {student.full_name}
                                    </td>
                                    <td >
                                        {student.father_name}
                                    </td>
                                    <td >
                                        {student.class}
                                    </td>
                                    <td >
                                        {student.school}
                                    </td>
                                    <td >
                                        {student.studentId}
                                    </td>
                                    <td >
                                        {student.roll_num}
                                    </td>
                                    <td >
                                        {student.percentage}
                                    </td>
                                    <td className="action-top">
                                        <Link to={`/admin/topStudents/${student._id}`} className="icons-action"><FaEdit /></Link>

                                        {
                                            <span onClick={() => activeHandleShow(student._id)} className="icons-action"><MdDeleteForever /></span>

                                        }


                                    </td>

                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                            }



                        </tbody>
                    </table  >
                    {
                        isLoadingStudent ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>

            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to delete student?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={deleteStudent}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}


            <ToastContainer />
        </div>
    )
}
export default TopStudents;