import React, { useEffect, useState, useCallback } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { GiPalm } from 'react-icons/gi';
// import { GiSwordwoman } from 'react-icons/gi';
import {FaExchangeAlt } from 'react-icons/fa';
import { BiLogOut, BiUser } from 'react-icons/bi';
import { Modal, Button } from 'react-bootstrap'
import { FaEye, FaEyeSlash, } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import PORTNUMBER from '../../../api/api'
import { useForm } from 'react-hook-form';
import { loginModalTrue, toasterValue, signUpModalTrue, userLoginTrue } from '../../../redux/actions/action'
import { useDispatch, useSelector } from 'react-redux';
import IMAGE_HOST from '../../../api/api'
import { ImageObj } from '../../../assets/images/images';
import LoginUser from '../../modal/login/loginUser';
import UserSignUp from '../../modal/signUpUser/signUpUser';
import useUserProfile from '../../../hooks/users/useUserProfile';
import useChangePassword from '../../../hooks/users/useChangePassword';
import { toast } from 'react-toastify';

const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const UserTokenId = localStorage.getItem("UserTokenId")
    const TOKEN = localStorage.getItem("token")
    const LoginPopUp = useSelector((state) => state.loginModalReducer)
    const SignUpPopUp = useSelector((state) => state.signUpModalReducer)
    const adminPath = location.pathname.split('/')[1];
    const { mutate: changePassword} = useChangePassword();
    const navigate = useNavigate()

    const useToggle = (initialState = false) => {
        const [state, setState] = useState(initialState);
        const toggle = useCallback(() => setState(state => !state), []);
        return [state, toggle]
    }

    const PORT = PORTNUMBER.PORTNUMBER
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const [scroll, setScroll] = useState();
    const [isOn, toggleIsOn] = useToggle(!true);
    const [language, setLanguage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passErr, setPassErr] = useState();
    const [currentpPassErr, setCurrentPassErr] = useState();
    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [classActive, seClassActive] = useState("")
     const { data: userIdData } = useUserProfile({ PORT, UserTokenId, TOKEN });

    useEffect(() => {
        if (isOn === true) {
            toggleIsOn()
        }
    }, [LoginPopUp])

    useEffect(() => {
        if (String(adminPath) !== "") {
            seClassActive("deactive")
        } else {
            seClassActive("")

        }
    }, [adminPath])


    const { register, handleSubmit, reset } = useForm();
    const handleShowPw = () => setShowPassword(true);
    const handleClosePw = () => {
        setShowPassword(false)
        setVisibilityNew(true)
        setVisibility(true)
        setVisibilityConfirm(true)
        reset()
    }


    const LogOut = () => {
        dispatch(userLoginTrue(false))
        localStorage.removeItem("RefId");
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("UserTokenId");
        navigate("/")
        dispatch(toasterValue("You have logged out successfully."))
        toggleIsOn(!true)

    }

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }


    const { i18n } = useTranslation();
    function HandelClick(lang) {
        i18n.changeLanguage(lang);
        setLanguage(!language)
        toggleIsOn(!true)
    }

    const onSubmit = (data) => {
        if (data.newPassword === data.confirm_password) {
            setPassErr("");
            changePassword(
                { password: data.password, newPassword: data.newPassword,TOKEN },
                {
                    onSuccess: (res) => {
                        if (res.success !== false) {
                           handleClosePw();
                            reset();
                           LogOut();
                           toggleIsOn(false);
                            setCurrentPassErr("");
                            toast("Password changed successfully.");
                        } else {
                            setCurrentPassErr(res.message);
                        }
                    },
                    onError: (error) => {
                        setCurrentPassErr("Technical Error");
                    }
                }
            );
        } else {
            setPassErr("Confirm password not matched");
        }
    };



    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'normal';
            if (window.scrollY > 50) {
                activeClass = 'active-header';
            }
            setScroll(activeClass);

        })

    }, [])



    return (
        <React.Fragment>
        
            <header className={`top-bar home-header ${scroll}`} >
                {/* <header > */}
                <div className="container">
                    <div className="col-sm-12 p-0 header-menu">
                        <div className="row">
                            <div className="col-lg-3" >
                                <div className="top-menu-bar d-flex">
                                    <Link to="/" className="logo">
                                        <GiPalm />
                                        <div className="logo-text">
                                            <h1>KOLI SAMAJ
                                                <span className='d-block'>VIKASH SANSTHA</span>
                                            </h1>

                                        </div>
                                    </Link>
                                    <div className="toggle-top" onClick={toggleIsOn}>
                                        <div id="toggle" className={isOn ? 'on' : 'off'}>
                                            <div className="one" />
                                            <div className="two" />
                                            <div className="three" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-9 menu-links  ${isOn ? 'side-slide' : 'OFF'}`} >
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="links">
                                            <ul>

                                                <li onClick={toggleIsOn}>
                                                    <NavLink className={String(classActive)} to="/">Home<MdKeyboardArrowRight /></NavLink>
                                                </li >

                                                <li onClick={toggleIsOn}>
                                                    <NavLink to="matrimonial">Matrimonial<MdKeyboardArrowRight /></NavLink>
                                                </li >
                                                <li onClick={toggleIsOn}>
                                                    <NavLink to="allHistory">AllHistory <MdKeyboardArrowRight /></NavLink>
                                                </li>
                                                <li onClick={toggleIsOn}>
                                                    <NavLink to="publicPost">Public Post <MdKeyboardArrowRight /></NavLink>
                                                </li>

                                                <li onClick={toggleIsOn}>
                                                    <NavLink to="help">Help <MdKeyboardArrowRight /></NavLink>
                                                </li>
                                                <li className="d-none" onClick={toggleIsOn}>
                                                    <NavLink to="history">History <MdKeyboardArrowRight /></NavLink>
                                                </li>

                                                <li className="d-none" onClick={toggleIsOn}>
                                                    <NavLink to="success-life">SuccessLife <MdKeyboardArrowRight /></NavLink>
                                                </li>
                                                <li className="d-none" onClick={toggleIsOn}>
                                                    <NavLink to="profile">profile <MdKeyboardArrowRight /></NavLink>
                                                </li>
                                                {/* <li >
                                                    <NavLink disabled   to="#">Donors <MdKeyboardArrowRight /></NavLink>
                                                    <ul>
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink    to="activeDonors">Active Donors </NavLink>
                                                        </li>
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink    to="atTimeDonors">At Time Donors</NavLink>
                                                        </li >

                                                    </ul>
                                                </li> */}
                                                <li >
                                                    <NavLink to={"allMember"}>Members  <MdKeyboardArrowRight /></NavLink>
                                                    <ul>

                                                        <li onClick={toggleIsOn}>
                                                            <NavLink to="/allMember">All Members </NavLink>
                                                        </li >
                                                        {/* <li onClick={toggleIsOn}>
                                                            <NavLink to="/allMember/PoliticiansList">Politician</NavLink>
                                                        </li> */}
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink to="/allMember/govtEmployeeList">Govt Employee</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                                {/* <li >
                                                    <NavLink  exact  to="/addMemberForm">Form <MdKeyboardArrowRight /></NavLink>
                                                    <ul>
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink  exact  to="/addMemberForm">Member </NavLink>
                                                        </li>
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink  exact  to="/politicianForm">Politician</NavLink>
                                                        </li >
                                                        <li onClick={toggleIsOn}>
                                                            <NavLink  exact  to="/govtEmployee">Govt Employee</NavLink>
                                                        </li>
                                                    </ul>
                                                </li> */}
                                                {/* <li onClick={toggleIsOn}>
                                                    <NavLink exact  to="/aboutUs">About Us <MdKeyboardArrowRight /></NavLink>
                                                </li> */}

                                                <li onClick={toggleIsOn}>
                                                    <NavLink to="addMemberForm">Join Us<MdKeyboardArrowRight /></NavLink>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className=" brown-button menu-btn languages">
                                            {
                                                !language ? <button onClick={() => HandelClick('en')} className="btn">English</button> : <button onClick={() => HandelClick('hi')} className="btn">हिंदी</button>
                                            }

                                            {UserTokenId ?
                                                <div className="user-profile">
                                                    {/* <Link to={`/profile/${UserTokenId}`} >
                                            <FaRegUserCircle />
                                        </Link> */}

                                                    <div className="d-flex user-top-user">
                                                        <div className="top-user">
                                                            {userIdData?.image ?
                                                                <img src={`${HOST_PORT}/${userIdData?.image}`} alt="" />
                                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />

                                                            }
                                                        </div>

                                                        <div className="dropdown">
                                                            <div className="drop-inner">
                                                                <Link to={`/profile/${UserTokenId}`} onClick={toggleIsOn} className="drop-click"> <BiUser />Profile</Link>
                                                                <span onClick={handleShowPw} className="drop-click second"> <FaExchangeAlt />Change Password</span>
                                                                <span onClick={LogOut} className="drop-click"> <BiLogOut />Logout</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <button onClick={() => dispatch(loginModalTrue(true))} className="btn">Login</button>

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {/* modal edit */}
                <div className="modal-edit">
                    <Modal show={showPassword} onHide={handleClosePw} className="edit-content">

                        <div className="max-new-second">
                            <div className="main-theme bg-theme border shadow">
                                <Modal.Header closeButton>
                                    <div className="select-steps-box">
                                        <div className="input-head">
                                            <h2>Change Password</h2>
                                        </div>
                                    </div>
                                </Modal.Header>
                                <section className="select-state">
                                    <div className="state-bor">
                                        <div className="log-in otp-num">

                                            <div className="select-box-top pr-0">
                                                <div className="input-box">
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="col-12 p-0">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-group icon-position">
                                                                        <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Current Password" required />
                                                                        {
                                                                            visibility ?
                                                                                <span className="icon" onClick={() => pwVisibility(false)}>
                                                                                    <FaEye />
                                                                                </span> :
                                                                                <span className="icon" onClick={() => pwVisibility(true)}>
                                                                                    <FaEyeSlash />
                                                                                </span>
                                                                        }
                                                                        <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="form-group icon-position">
                                                                        <input type={visibilityNew ? "password" : "text"} className="form-control"  {...register('newPassword', { required: true })} placeholder="New Password" required />
                                                                        {
                                                                            visibilityNew ?
                                                                                <span className="icon" onClick={() => pwVisibilityNew(false)}>
                                                                                    <FaEye />
                                                                                </span> :
                                                                                <span className="icon" onClick={() => pwVisibilityNew(true)}>
                                                                                    <FaEyeSlash />
                                                                                </span>
                                                                        }
                                                                        <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="form-group icon-position">
                                                                        <input type={visibilityConfirm ? "password" : "text"} className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm Password" required />
                                                                        {
                                                                            visibilityConfirm ?
                                                                                <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                                    <FaEye />
                                                                                </span> :
                                                                                <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                                    <FaEyeSlash />
                                                                                </span>
                                                                        }
                                                                        <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                                                    </div>
                                                                </div>

                                                                <Modal.Footer>
                                                                    <div className="theme-btn btn-group-right">
                                                                        <Button type="submit" className="btn" variant="secondary" >
                                                                            Update
                                                                        </Button>
                                                                        <Button type="reset" className="btn" variant="primary" onClick={handleClosePw}>
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                </Modal.Footer>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                    </Modal>
                </div>
            </header>
            <Outlet />
            <LoginUser show={LoginPopUp} onHide={() => dispatch(loginModalTrue(false))} />
            <UserSignUp show={SignUpPopUp} onHide={() => dispatch(signUpModalTrue(false))} />

        </React.Fragment>
    )

}

export default Header