import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';

const deleteMatrimonial = async ({ statusId, PORT, TOKEN }) => {
    const response = await axios.post(`${PORT}/user/marriage/delete`, 
        { marriageId: statusId }, 
        { headers: { authorization: `Bearer ${TOKEN}` } }
    );
    return response.data;
};

const useDeleteMatrimonial = (PORT, TOKEN) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ statusId }) => deleteMatrimonial({ statusId, PORT, TOKEN }),
        onSuccess: () => {
            toast.success("Deleted successfully.");
            queryClient.invalidateQueries(['deleteMatrimonials']); // Adjust the query key if needed
        },
    });
};

export default useDeleteMatrimonial;
