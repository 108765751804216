import React, {useEffect, useRef } from 'react'
import { ImageObj } from '../../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { PDFExport } from "@progress/kendo-react-pdf";
import PORTNUMBER from '../../../../api/api'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import IMAGE_HOST from '../../../../api/api'
import * as htmlToImage from 'html-to-image';
import useMatrimonialProfile from '../../../../hooks/matrimonial/useMatrimonialProfile';



const ViewApplicant = () => {
    const { t } = useTranslation();
    const TOKEN = localStorage.getItem("token")
    const PORT = PORTNUMBER.PORTNUMBER
    const { id } = useParams()
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    const { data: applicantById } = useMatrimonialProfile({ PORT, id, TOKEN });



    useEffect(() => {
        document.body.classList.add("change-bg")
        return () => {
            document.body.classList.remove("change-bg")
        }
    }, [])



    const pdfExportComponent = useRef(null)
    const contentArea = useRef(null)

    const handelExportWithComponent = (event) => {
        pdfExportComponent.current.save()
    }

    // const handelExportWithMethod = (event) => {
    //     savePDF(contentArea.current, { paperSize: "A4" })
    // }

    const takScrenShot = () => {
        htmlToImage.toJpeg(document.getElementById('my-node'), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                link.href = dataUrl;
                link.click();
            });
    }



    return (

        <div className="container">
            <div className="pdf-box ">
                <div className="pdf-one specific"  >
                    <div className="marrige-applicant " >
                        <div className="applicant-box" >
                            <div className="appl-header">
                                <h3>{applicantById?.full_name}</h3>
                                <h5>{applicantById?.occupation}</h5>
                            </div>
                            <div className="appli-view ">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-5">
                                                <div className="applic-box shadow">
                                                    <div className="box-img" >
                                                        {applicantById?.image ?
                                                            <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />
                                                            :
                                                            !applicantById?.image ?
                                                                <img aria-hidden="true" src={ImageObj.Teacher1} alt="koli marrige" /> :
                                                                <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />

                                                        }
                                                    </div>
                                                    <div className="applicant-data">
                                                        <ul>
                                                            <li>{t('Marriage_Data.PersonalDetails.FullName')} :</li>
                                                            <li>{applicantById?.full_name}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.Cast')} :</li>
                                                            <li>{applicantById?.caste}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} :</li>
                                                            <li>{applicantById?.marital_status}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.Birth_date')} :</li>
                                                            <li>{applicantById?.dob !==undefined ? moment(applicantById?.dob).format("DD/MM/YYYY") : "N/a"}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.Height.head')} :</li>
                                                            <li>{applicantById?.height}</li>
                                                            {/* {
                                                                applicantById?.blood_group ?
                                                                    <>
                                                                        <li>{t('Marriage_Data.PersonalDetails.Blood_Group.head')} :</li>
                                                                        <li>{applicantById?.blood_group}</li>
                                                                    </> : ""
                                                            } */}

                                                            {
                                                                applicantById?.complexion ?
                                                                    <>
                                                                        <li>{t('Marriage_Data.PersonalDetails.Complexion.head')} :</li>
                                                                        <li>{applicantById?.complexion}</li>
                                                                    </> : ""
                                                            }



                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-8 col-sm-7">
                                                <div className="bio-data-box">
                                                    <div className="box-bio ">
                                                        <div className="head first">
                                                            <h3>{t('Marriage_Data.Horoscope_Details.Heading')}</h3>
                                                        </div>
                                                        <div className="content">
                                                            <ul>
                                                                {/* {
                                                                    applicantById?.time_of_birth ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.Horoscope_Details.Details')} :</li>
                                                                            <li>{applicantById?.time_of_birth}</li>
                                                                        </> : ""
                                                                } */}

                                                                <li>{t('Marriage_Data.Horoscope_Details.Details2')} :</li>
                                                                <li>{applicantById?.place_of_birth}</li>
                                                                <li>{t('Marriage_Data.Horoscope_Details.Mangal.head')} :</li>
                                                                <li>{applicantById?.mangal}</li>
                                                                <li>{t('Marriage_Data.Horoscope_Details.Details3')} :</li>
                                                                <li>{applicantById?.gotra}</li>
                                                                <li>{t('Marriage_Data.Gender')} :</li>
                                                                <li>{applicantById?.gender}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="box-bio ">
                                                        <div className="head ">
                                                            <h3>{t('Marriage_Data.Education.Heading')}</h3>
                                                        </div>
                                                        <div className="content">
                                                            <ul>
                                                                <li>{t('Marriage_Data.Education.Detail_edu')} :</li>
                                                                <li>{applicantById?.education_level}</li>
                                                                <li>{t('Marriage_Data.Education.Detail_edu2')} :</li>
                                                                <li>{applicantById?.education_details}</li>
                                                                <li>{t('Marriage_Data.Education.Detail_edu3')} :</li>
                                                                <li>{applicantById?.occupation}</li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="bio-data-box last">


                                            <div className="box-bio ">
                                                <div className="head ">
                                                    <h3>{t('Marriage_Data.Family_Background.Heading')}</h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="content">
                                                            <ul>
                                                                <li>{t('Marriage_Data.Family_Background.Detail1.head')} :</li>
                                                                <li>{applicantById?.father}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail2.head')} :</li>
                                                                <li>{applicantById?.mother}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail3')} :</li>
                                                                <li>{applicantById?.father_name}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail4')} :</li>
                                                                <li>{applicantById?.mother_name}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail5')} :</li>
                                                                <li>{applicantById?.total_brothers}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail6')} :</li>
                                                                <li>{applicantById?.married_brothers}</li>
                                                                

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="content">
                                                            <ul>


                                                                <li>{t('Marriage_Data.Family_Background.Detail9')} :</li>
                                                                <li>{applicantById?.married_sisters}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail10')} :</li>
                                                                <li>{applicantById?.residential_address}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail11')} :</li>
                                                                <li>{applicantById?.city}</li>
                                                                <li>State :</li>
                                                                <li>{applicantById?.state}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail7')} :</li>
                                                                <li>{applicantById?.parents_contact_no}</li>
                                                                <li>{t('Marriage_Data.Family_Background.Detail8')} :</li>
                                                                <li>{applicantById?.total_sisters}</li>
                                                                {/* <li>{t('Marriage_Data.Family_Background.Detail12')} :</li>
                                                                <li>{applicantById?.father_occupation}</li> */}
                                                                {/* {
                                                                    applicantById?.maternal_uncle_place ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.Family_Background.Detail13')} :</li>
                                                                            <li>{applicantById?.maternal_uncle_place}</li>
                                                                        </> : ""
                                                                } */}
                                                                {/* {
                                                                    applicantById?.surname_relatives ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.Family_Background.Detail14')} :</li>
                                                                            <li>{applicantById?.surname_relatives}</li>
                                                                        </> : ""
                                                                } */}


                                                                {/* {
                                                                    applicantById?.expectations_from_partner ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.Family_Background.Detail15')} :</li>
                                                                            <li>{applicantById?.expectations_from_partner}</li>
                                                                        </> : ""
                                                                } */}


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="pdf-two ">
                    <div>
                        <div className="marrige-applicant " ref={contentArea}>
                            <div className="applicant-box mh-auto" id="my-node">
                                <div className="appl-header">
                                    <h3>{applicantById?.full_name}</h3>
                                    <h5>{applicantById?.occupation}</h5>
                                </div>
                                <div className="appli-view ">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="applic-box shadow">
                                                        <div className="box-img">
                                                            {applicantById?.image ?
                                                                <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />
                                                                :
                                                                !applicantById?.image ?
                                                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="koli marrige" /> :
                                                                    <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />

                                                            }
                                                        </div>
                                                        <div className="applicant-data">
                                                            <ul>
                                                                <li>{t('Marriage_Data.PersonalDetails.FullName')} :</li>
                                                                <li>{applicantById?.full_name}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Cast')} :</li>
                                                                <li>{applicantById?.caste}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} :</li>
                                                                <li>{applicantById?.marital_status}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Birth_date')} :</li>
                                                                <li>{moment(applicantById?.dob).format("DD/MM/YYYY")}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Height.head')} :</li>
                                                                <li>{applicantById?.height}</li>
                                                                {
                                                                    applicantById?.blood_group ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.PersonalDetails.Blood_Group.head')} :</li>
                                                                            <li>{applicantById?.blood_group}</li>
                                                                        </> : ""
                                                                }
                                                                {
                                                                    applicantById?.complexion ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.PersonalDetails.Complexion.head')} :</li>
                                                                            <li>{applicantById?.complexion}</li>
                                                                        </> : ""
                                                                }




                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-7">
                                                    <div className="bio-data-box">
                                                        <div className="box-bio ">
                                                            <div className="head first">
                                                                <h3>{t('Marriage_Data.Horoscope_Details.Heading')}</h3>
                                                            </div>
                                                            <div className="content">
                                                                <ul>
                                                                    {
                                                                        applicantById?.time_of_birth ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Horoscope_Details.Details')} :</li>
                                                                                <li>{applicantById?.time_of_birth}</li>
                                                                            </> : ""
                                                                    }

                                                                    <li>{t('Marriage_Data.Horoscope_Details.Details2')} :</li>
                                                                    <li>{applicantById?.place_of_birth}</li>
                                                                    <li>{t('Marriage_Data.Horoscope_Details.Mangal.head')} :</li>
                                                                    <li>{applicantById?.mangal}</li>
                                                                    <li>{t('Marriage_Data.Horoscope_Details.Details3')} :</li>
                                                                    <li>{applicantById?.gotra}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="box-bio ">
                                                            <div className="head ">
                                                                <h3>{t('Marriage_Data.Education.Heading')}</h3>
                                                            </div>
                                                            <div className="content">
                                                                <ul>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu')} :</li>
                                                                    <li>{applicantById?.education_level}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu2')} :</li>
                                                                    <li>{applicantById?.education_details}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu3')} :</li>
                                                                    <li>{applicantById?.occupation}</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="bio-data-box last">


                                                <div className="box-bio ">
                                                    <div className="head ">
                                                        <h3>{t('Marriage_Data.Family_Background.Heading')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="content">
                                                                <ul>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail1.head')} :</li>
                                                                    <li>{applicantById?.father}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail2.head')} :</li>
                                                                    <li>{applicantById?.mother}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail3')} :</li>
                                                                    <li>{applicantById?.father_name}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail4')} :</li>
                                                                    <li>{applicantById?.mother_name}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail5')} :</li>
                                                                    <li>{applicantById?.total_brothers}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail6')} :</li>
                                                                    <li>{applicantById?.married_brothers}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail7')} :</li>
                                                                    <li>{applicantById?.parents_contact_no}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail8')} :</li>
                                                                    <li>{applicantById?.total_sisters}</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="content">
                                                                <ul>


                                                                    <li>{t('Marriage_Data.Family_Background.Detail9')} :</li>
                                                                    <li>{applicantById?.married_sisters}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail10')} :</li>
                                                                    <li>{applicantById?.residential_address}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail11')} :</li>
                                                                    <li>{applicantById?.city}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail12')} :</li>
                                                                    <li>{applicantById?.father_occupation}</li>
                                                                    {
                                                                        applicantById?.maternal_uncle_place ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail13')} :</li>
                                                                                <li>{applicantById?.maternal_uncle_place}</li>
                                                                            </> : ""
                                                                    }
                                                                    {
                                                                        applicantById?.surname_relatives ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail14')} :</li>
                                                                                <li>{applicantById?.surname_relatives}</li>
                                                                            </> : ""
                                                                    }


                                                                    {
                                                                        applicantById?.expectations_from_partner ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail15')} :</li>
                                                                                <li>{applicantById?.expectations_from_partner}</li>
                                                                            </> : ""
                                                                    }


                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="pdf-two ">
                    <PDFExport ref={pdfExportComponent} paperSize="A4">
                        <div className="marrige-applicant " ref={contentArea}>
                            <div className="applicant-box " >
                                <div className="appl-header">
                                    <h3>{applicantById?.full_name}</h3>
                                    <h5>{applicantById?.occupation}</h5>
                                </div>
                                <div className="appli-view ">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="applic-box shadow">
                                                        <div className="box-img">
                                                            {applicantById?.image ?
                                                                <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />
                                                                :
                                                                !applicantById?.image ?
                                                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="koli marrige" /> :
                                                                    <img src={`${HOST_PORT}/${applicantById?.image}`} alt="koli marrige" />

                                                            }
                                                        </div>
                                                        <div className="applicant-data">
                                                            <ul>
                                                                <li>{t('Marriage_Data.PersonalDetails.FullName')} :</li>
                                                                <li>{applicantById?.full_name}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Cast')} :</li>
                                                                <li>{applicantById?.caste}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} :</li>
                                                                <li>{applicantById?.marital_status}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Birth_date')} :</li>
                                                                <li>{moment(applicantById?.dob).format("DD/MM/YYYY")}</li>
                                                                <li>{t('Marriage_Data.PersonalDetails.Height.head')} :</li>
                                                                <li>{applicantById?.height}</li>
                                                                {
                                                                    applicantById?.blood_group ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.PersonalDetails.Blood_Group.head')} :</li>
                                                                            <li>{applicantById?.blood_group}</li>
                                                                        </> : ""
                                                                }
                                                                {
                                                                    applicantById?.complexion ?
                                                                        <>
                                                                            <li>{t('Marriage_Data.PersonalDetails.Complexion.head')} :</li>
                                                                            <li>{applicantById?.complexion}</li>
                                                                        </> : ""
                                                                }




                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-7">
                                                    <div className="bio-data-box">
                                                        <div className="box-bio ">
                                                            <div className="head first">
                                                                <h3>{t('Marriage_Data.Horoscope_Details.Heading')}</h3>
                                                            </div>
                                                            <div className="content">
                                                                <ul>
                                                                    {
                                                                        applicantById?.time_of_birth ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Horoscope_Details.Details')} :</li>
                                                                                <li>{applicantById?.time_of_birth}</li>
                                                                            </> : ""
                                                                    }

                                                                    <li>{t('Marriage_Data.Horoscope_Details.Details2')} :</li>
                                                                    <li>{applicantById?.place_of_birth}</li>
                                                                    <li>{t('Marriage_Data.Horoscope_Details.Mangal.head')} :</li>
                                                                    <li>{applicantById?.mangal}</li>
                                                                    <li>{t('Marriage_Data.Horoscope_Details.Details3')} :</li>
                                                                    <li>{applicantById?.gotra}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="box-bio ">
                                                            <div className="head ">
                                                                <h3>{t('Marriage_Data.Education.Heading')}</h3>
                                                            </div>
                                                            <div className="content">
                                                                <ul>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu')} :</li>
                                                                    <li>{applicantById?.education_level}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu2')} :</li>
                                                                    <li>{applicantById?.education_details}</li>
                                                                    <li>{t('Marriage_Data.Education.Detail_edu3')} :</li>
                                                                    <li>{applicantById?.occupation}</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="bio-data-box last">


                                                <div className="box-bio ">
                                                    <div className="head ">
                                                        <h3>{t('Marriage_Data.Family_Background.Heading')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="content">
                                                                <ul>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail1.head')} :</li>
                                                                    <li>{applicantById?.father}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail2.head')} :</li>
                                                                    <li>{applicantById?.mother}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail3')} :</li>
                                                                    <li>{applicantById?.father_name}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail4')} :</li>
                                                                    <li>{applicantById?.mother_name}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail5')} :</li>
                                                                    <li>{applicantById?.total_brothers}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail6')} :</li>
                                                                    <li>{applicantById?.married_brothers}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail7')} :</li>
                                                                    <li>{applicantById?.parents_contact_no}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail8')} :</li>
                                                                    <li>{applicantById?.total_sisters}</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="content">
                                                                <ul>


                                                                    <li>{t('Marriage_Data.Family_Background.Detail9')} :</li>
                                                                    <li>{applicantById?.married_sisters}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail10')} :</li>
                                                                    <li>{applicantById?.residential_address}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail11')} :</li>
                                                                    <li>{applicantById?.city}</li>
                                                                    <li>{t('Marriage_Data.Family_Background.Detail12')} :</li>
                                                                    <li>{applicantById?.father_occupation}</li>
                                                                    {
                                                                        applicantById?.maternal_uncle_place ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail13')} :</li>
                                                                                <li>{applicantById?.maternal_uncle_place}</li>
                                                                            </> : ""
                                                                    }
                                                                    {
                                                                        applicantById?.surname_relatives ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail14')} :</li>
                                                                                <li>{applicantById?.surname_relatives}</li>
                                                                            </> : ""
                                                                    }


                                                                    {
                                                                        applicantById?.expectations_from_partner ?
                                                                            <>
                                                                                <li>{t('Marriage_Data.Family_Background.Detail15')} :</li>
                                                                                <li>{applicantById?.expectations_from_partner}</li>
                                                                            </> : ""
                                                                    }


                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PDFExport>

                </div>
                <div className="theme-btn btn-group-right">
                    <button onClick={takScrenShot} className="btn">Download Image</button>
                    <button type="button" className="btn" onClick={handelExportWithComponent}>Download Pdf</button>
                </div>



            </div>

        </div>
    )
}

export default ViewApplicant;