import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const changeUserStatus = async ({ userId, status, type, AdminToken, PORT_ADMIN }) => {
    const response = await axios.post(
        `${PORT_ADMIN}/user/status/change`,
        { userId, status, type },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useChangeUserStatus = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ userId, status, type, AdminToken, PORT_ADMIN }) => changeUserStatus({ userId, status, type, AdminToken, PORT_ADMIN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['usersStatus']); // Adjust the query key if needed
        },
    });
};

export default useChangeUserStatus;
