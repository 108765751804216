
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import PORTNUMBER from '../../api/api'

// Function to fetch data with pagination
const fetchPosts = async ({ pageParam = 1, queryKey }) => {


    const TOKEN = localStorage.getItem("token")
    const PORT = PORTNUMBER.PORTNUMBER

  // Determine the endpoint based on whether filter exists and is non-empty
  const endpoint = `${PORT}/user/post/all`;
  
  // Construct the params object
  const params = { page: pageParam, limit: 100};

  const response = await axios.post(endpoint, params, {
    headers: {
      authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json',
    },
  });
  
  return {
    data: response.data.data.postDetails,
    nextPage: pageParam + 1, // The next page to load
  };
};

// Custom hook using `useInfiniteQuery` for lazy loading
export const usePaginatedPosts = (customString) => {
  return useInfiniteQuery({
    queryFn: fetchPosts ,
    getNextPageParam: (lastPage) => lastPage.nextPage, // Get the next page parameter
    // Optional configurations
    staleTime: 5000, // Data freshness duration
    cacheTime: 60000, // Cache time
    retry: 2, // Retry attempts
    queryKey: ['posts', customString],
  });
};