import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const userDelete = async ({ userId, AdminToken, PORT_ADMIN }) => {
    const response = await axios.post(
        `${PORT_ADMIN}/user/delete`,
        { userId },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useUserDelete = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ userId, AdminToken, PORT_ADMIN }) => userDelete({ userId, AdminToken, PORT_ADMIN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['usersDelete']); // Adjust the query key if needed
        },
    });
};

export default useUserDelete;
