import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const submitPeople = async ({ data, imageFile, PORT_ADMIN, AdminToken }) => {
    const fs = new FormData();
    for (const key in data) {
        fs.append(key, data[key]);
    }
    fs.append("image", imageFile);

    const response = await axios.post(`${PORT_ADMIN}/helpPeople/create`, fs, {
        headers: { authorization: `Bearer ${AdminToken}` },
    });

    return response.data;
};

const useSubmitPeople = (PORT_ADMIN, AdminToken) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ data, imageFile }) => submitPeople({ data, imageFile, PORT_ADMIN, AdminToken }),
        onSuccess: () => {
            queryClient.invalidateQueries(['submitpeople']); // Adjust the query key if needed
        },
    });
};

export default useSubmitPeople;
