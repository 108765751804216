import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';

const deleteFeedback = async ({ statusId, PORT_ADMIN, AdminToken }) => {
    const response = await axios.post(`${PORT_ADMIN}/feedbacks/delete`, 
        { feedbackId: statusId }, 
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useDeleteFeedback = (PORT_ADMIN, AdminToken) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ statusId }) => deleteFeedback({ statusId, PORT_ADMIN, AdminToken }),
        onSuccess: () => {
            toast.success("Feedback is deleted successfully.");
            queryClient.invalidateQueries(['deleteFeedback']); // Adjust the query key if needed
        },
    });
};

export default useDeleteFeedback;
