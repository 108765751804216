import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const submitPosts = async ({ data, imageFile, Username, PORT, TOKEN }) => {
    const fs = new FormData();
    for (const key in data) {
        fs.append(key, data[key]);
    }
    fs.append("image", imageFile);
    fs.append('title', Username);


    const response = await axios.post(`${PORT}/user/post/create`, fs, {
        headers: { authorization: `Bearer ${TOKEN}` },
    });

    return response.data;
};

const useSubmitPosts = (PORT, TOKEN) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ data, imageFile, Username, }) => submitPosts({ data, imageFile, Username, PORT, TOKEN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['submitPosts']); // Adjust the query key if needed
        },
    });
};

export default useSubmitPosts;
