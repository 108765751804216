import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const editMatrimonial = async ({  data, imageFile, id, PORT, TOKEN, marriageDetail }) => {
    const fs = new FormData();
    fs.append("marriageId", id);


    for (const key in data) {
        data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? marriageDetail[key] : data[key];
        fs.append(key, data[key]);
    }
    

    if (imageFile > 0) {
        const File = document.getElementById('marriageImage').files[0];
        fs.append("image", File);
    }

    const response = await axios.post(`${PORT}/user/marriage/update`, fs, {
        headers: { authorization: `Bearer ${TOKEN}` }
    });

    return response.data;
};

const useEditMatrimonial = (PORT, TOKEN, marriageDetail) => {
    return useMutation({
        mutationFn: ({ data, imageFile, id, }) => editMatrimonial({  data, imageFile, id, TOKEN, PORT, marriageDetail }),
    });
};

export default useEditMatrimonial;
