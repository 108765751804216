import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const uploadImages = async ({ imageFiles, id, AdminToken, PORT_ADMIN }) => {
  const fd = new FormData();

  for (let i = 0; i < imageFiles.length; i++) {
    fd.append("images", imageFiles[i]);
  }
  fd.append("eventId", id);

  const headerConfig = {
    headers: {
      'authorization': `Bearer ${AdminToken}`,
      'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
    },
    onUploadProgress: (progressEvent) => {
      // Optionally, you can handle progress here if needed.
    },
  };

  const response = await axios.post(`${PORT_ADMIN}/event/image/upload`, fd, headerConfig);
  return response.data;
};

const useUploadImages = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ imageFiles, id, AdminToken, PORT_ADMIN }) => uploadImages({ imageFiles, id, AdminToken, PORT_ADMIN }),
    onSuccess: () => {
      queryClient.invalidateQueries(['images']); // Adjust the query key if needed
    },
  });
};

export default useUploadImages;
