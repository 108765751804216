import React, { useEffect } from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LaxmiBai = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return (() => {
            document.body.classList.remove("choclate-bg");

        })
    }, [])


    return (
        <React.Fragment>
       <Helmet>
            <title>Jhalkari Bai Koli History - Heroine of the Jhansi Rebellion</title>
            <meta name="description" content="Discover the inspiring story of Jhalkari Bai, the courageous commander of Durga Dal and a key figure in the Indian Rebellion of 1857. Learn about her role in the defense of Jhansi and her bravery in helping Rani Lakshmibai escape from the British forces." />
            <meta name="keywords" content="Jhalkari Bai, Durga Dal, Jhansi Rebellion, Rani Lakshmibai, Bundelkhand history, Indian freedom fighters, Jhalkari Bai biography, Koli history, Sadovar Singh, Jamuna Devi" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/jhalkariBai" />
        </Helmet>

        <div className="container padding-mob-0">
            <div className="history-show-top bg" style={{ backgroundImage: `url(${ImageObj.ShowHisBg2})` }}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                       <h2>{t('HisJhalkari.titleOne')}</h2>
                        <p>{t('HisJhalkari.summeryOne')}</p>
                        <h2>{t('HisJhalkari.titleTwo')}</h2>
                        <p>{t('HisJhalkari.summeryTwo')}</p>
                        <h2>{t('HisJhalkari.titleThree')}</h2>
                        <p>{t('HisJhalkari.summeryThree')}</p>
                        <h2>{t('HisJhalkari.titleFour')}</h2>
                        <p>{t('HisJhalkari.summeryFour')}</p>
                        <p style={{"font-weight":"normal"}}>{t('HisJhalkari.summeryFive')}<span className="d-block"></span>
                        {t('HisJhalkari.summerySix')}</p>
                        <p>{t('HisJhalkari.summerySeven')}</p>
                        <p style={{"font-weight":"normal"}}>{t('HisJhalkari.summeryEight')}<span className="d-block"></span>
                        {t('HisJhalkari.summeryNine')}<span className="d-block"></span>
                        {t('HisJhalkari.summeryTen')}</p>

                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default LaxmiBai;