import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import IMAGE_HOST from '../../../../api/api'
import { ImageObj } from '../../../../assets/images/images'
import ProgressBar from "@ramonak/react-progress-bar";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import PORTNUMBER_ADMIN from '../../../../api/api'
import { centerSlider, benifited } from '../../../sliders/sliders'
import usePeopleList from "../../../../hooks/helpPeople/useList";
import { FaUser } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import useFeedbacksList from "../../../../hooks/feedBacks/useList";
import useCampList from "../../../../hooks/Camps/useList";



const Donation = () => {
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const [currentIndex, setCurrentIndex] = useState(null);
    const { data: allPeople, isLoading: isLoadingPeople, } = usePeopleList(PORT_ADMIN);
    const { data: allFeedbacks, isLoading: isLoadingFeedback, } = useFeedbacksList(PORT_ADMIN);
    const { data: allCamps, isLoading: isLoadingCamps, } = useCampList(PORT_ADMIN);



    console.log("allPeople", allPeople)


    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (currentIndex !== null) {
            handleShow(); 
        }
    }, [currentIndex]);


    return (

        <React.Fragment>
            <Helmet>
                <title>Help Koli Samaj People - Empower Through Education & Support</title>
                <meta name="description" content="Join us in our mission to support the Koli community. Help the poor and helpless by sharing knowledge and resources. Education is the key to overcoming life's challenges." />
                <meta name="keywords" content="Koli help, Koli community support, Help Koli poor people, Koli education assistance, Koli study help" />
                <meta name="author" content="Mahendra Koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/help" />
            </Helmet>



            {/* <section className="">
                <Slider {...settings}>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Hands} />
                    </div>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Poor} />
                    </div>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Study} />
                    </div>
                </Slider>
            </section>
            <section className="donation-sec">
                <div className="container">
                    <div className="donation-box">
                        <div className="row">

                            <div className="col-md-4 col-sm-6">
                                <div className="classes-top">
                                    <div className="group-classes">
                                        <div className="group-top">
                                            <h4>Group Classes</h4>
                                            <h5>(All Inclusive)</h5>
                                        </div>
                                        <div className="group-mid">
                                            <h2 className="circle" />
                                            <p>Enrol into best youth fitness classes in DelhiNCR. You Get in:</p>
                                            <h3><i className="fas fa-rupee-sign" aria-hidden="true" />800</h3>
                                        </div>
                                        <div className="group-bottom">
                                            <p><i className="fas fa-check-circle mr-1" aria-hidden="true" />General Physical Literacy and Sports Readiness training</p>
                                            <p><i className="fas fa-check-circle mr-1" aria-hidden="true" />5 Sessions with Physiotherapist</p>
                                        </div>
                                        <div className="button-group">
                                            <button className="btn">Choose plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* banner section start */}
            <section className="banner">
                <div className="container">
                    <div className="shadow-blue">
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-4">
                        </div>
                        <div className="col-md-6 col-sm-8">
                            <div className="shadow-content text-center" data-aos="zoom-in">
                                <h2>{t('Donation.Home.Heading')}</h2>
                                <p>{t('Donation.Home.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn white rounded">Get a Quote</button>
                                </div>
                            </div>
                            {/* <div className="banner-icon">
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/home-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Homeowners</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/car-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Auto</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/buil-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Commercial</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* banner section end */}
            {/* couses donation start */}
            <section className="causes-donation">
                <div className="container">
                    <div className="row">
                        {
                           !isLoadingCamps ? allCamps?.map((camp, i)=>(
                                <div key={i + 1} className="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className="dobation-card">
                                    <div className="box-img">
                                        <img aria-hidden="true" src={`${HOST_PORT}/${camp.image}`} alt={camp.heading} />
                                    </div>
                                    <div className="top-pic-content-box">
                                        <div className="top-pic-content enjoy-con">
                                            <h3>{camp.heading}</h3>
                                            <div className="process">
                                                <ProgressBar completed={60} bgColor="#a78b60" borderRadius="0" />
                                            </div>
                                            <p>{camp.description}</p>
                                        </div>
    
                                        <div className="brown-button">
                                            <button className="btn">Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )) :
                            <div className="loader-wrapper absolute-sec">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <FaUser />
                                    </div>
                                </div>
                            </div>
                        }
                       
                    </div>
                </div>
            </section>
            {/* couses donation end */}

            {/* providing section start */}
            {/* <section className="providing">
                <div className="container">
                    <div className="providing-inner">
                        <div className="providing-head text-center">
                            <h3>Providing Florida Homeowners Peace of Mind Since 1999 </h3>
                            <p>When it comes to buying homeowners insurance you have a lot of </p>
                        </div>
                        <div className="providing-boxes">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/home2.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3>Health</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/building2.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3> Education</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/sun.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3>Support</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* providing section end */}


            {/* Benefited section start  */}
            <section className="benifited-people">
                <div className="benifi-slider" data-aos="fade-left"

                    data-aos-duration="500">
                    <Slider {...benifited}>
                        {

                            !isLoadingPeople ? allPeople?.map((people, i) => {

                                return (
                                    <div className="item" key={i + 1}>
                                        <div className="resume">

                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="image-points">
                                                        <div className="right-image">
                                                            <img aria-hidden="true" src={`${HOST_PORT}/${people?.image}`} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="home-left new">
                                                        <div className="plan">
                                                            <div className="head">
                                                                <p>परिवार</p>
                                                            </div>
                                                            <h2>{people.heading}</h2>
                                                        </div>
                                                        <div className="name-adress">
                                                            <div className="head">
                                                                <p>नाम</p>
                                                            </div>
                                                            <div className="points">
                                                                <ul>
                                                                    <li>{people.full_name}</li>
                                                                </ul>
                                                            </div>
                                                            <div className="head">
                                                                <p>पता</p>
                                                            </div>
                                                            <div className="points">
                                                                <ul>
                                                                    <li className="d-block">{people.full_Address}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="problem">
                                                            <div className="head">
                                                                <p>परिवार की समस्या</p>
                                                            </div>
                                                            <p>{people.description}</p>
                                                        </div>


                                                    </div>
                                                    <div className="brown-button" onClick={() => setCurrentIndex(i)}>
                                                        <button className="btn yellow" onClick={handleShow}>Read More</button>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )

                            }) :
                                <div className="loader-wrapper absolute-sec">
                                    <div className="loader">
                                        <div className="loading-svg">
                                            <FaUser />
                                        </div>
                                    </div>
                                </div>
                        }
                      
                    </Slider>
                </div>
            </section>
            {/* Benefited section end  */}



            <section className="testimonials-top donation">
                <Slider {...centerSlider}>
                    {
                !isLoadingFeedback ? allFeedbacks.length ? allFeedbacks?.map((feedback, i) => {
                    return (<div className="item" key={i + 1}>
                        <div className="testimonials-main">
                            <div className="testimonials">
                                <div className="test-img">
                                    <img aria-hidden="true" src={`${HOST_PORT}/${feedback?.image}`} alt="" />
                                </div>
                                <div className="test-con text-center">
                                    <h5>{feedback.full_name}</h5>
                                    <h6>{feedback.occupation}</h6>
                                    <p>{feedback.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>)
                     }):<span className="no-data d-block text-nowrap">feedbacks Not Found</span> :
                     <div className="loader-wrapper absolute-sec">
                         <div className="loader">
                             <div className="loading-svg">
                                 <FaUser />
                             </div>
                         </div>
                     </div>
             }

            
                </Slider>
            </section>
            <section className="insurance reverse">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-right">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_1.Head')}</h3>
                                <p>{t('HelpSection.Box_1.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0" data-aos="fade-left">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Study} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 p-0" data-aos="fade-right">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Hands} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-left">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_2.Head')}</h3>
                                <p>{t('HelpSection.Box_2.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-md-6" data-aos="fade-right">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_3.Head')}</h3>
                                <p>{t('HelpSection.Box_3.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0" data-aos="fade-left">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Poor} alt="" />
                        </div>
                    </div>

                </div>


            </section>
            {/* Conditionally render the Modal */}
            {currentIndex !== null && (
                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{allPeople[currentIndex]?.heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{allPeople[currentIndex]?.description}</Modal.Body>
                    <Modal.Footer>
                        <div className="brown-button">
                        <button className="btn yellow" onClick={handleClose}>
                            Close
                        </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </React.Fragment>
    )
}

export default Donation;

