import React, { useState, useEffect, useCallback, useRef } from 'react'
import { ImageObj } from '../../../assets/images/images'
import { Modal, Button, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PORTNUMBER from '../../../api/api'
import { FaEllipsisV } from 'react-icons/fa';
import { FcGallery } from 'react-icons/fc';
import IMAGE_HOST from '../../../api/api'
import { toast } from 'react-toastify'
import { RiLoader2Fill, RiUserSearchFill } from 'react-icons/ri';
import moment from 'moment'
import { useForm } from 'react-hook-form';
import useDeletePost from '../../../hooks/posts/useDeletePost'
import { usePaginatedPosts } from '../../../hooks/posts/usePaginatedPosts'
import useSubmitPosts from '../../../hooks/posts/useSubmitPosts'
import useUserProfile from '../../../hooks/users/useUserProfile'
import useSubmitPostUpdate from '../../../hooks/posts/useSubmitPostsUpdate'
import usePostById from '../../../hooks/posts/usePostById'

const FetchPublicPosts = () => {

    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const Username = localStorage.getItem("username")
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const [activeShow, setActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const [postIdEdit, setPostIdEdit] = useState();
    // const [postById, setPostById] = useState(["not"])
    const [postIdEditVisible, setPostIdEditVisible] = useState();

    // const [userIdData, setUserIdData] = useState(["not"])

    const { register, handleSubmit, reset } = useForm();

    const { mutate: deletePost } = useDeletePost(PORT, TOKEN);


    const { data: postList, isLoading, isError, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } = usePaginatedPosts();
    const { mutate: submitPosts } = useSubmitPosts(PORT, TOKEN);
    const { data: userIdData } = useUserProfile({ PORT, UserTokenId, TOKEN });
    const { data: postById, refetch: fetchPostById } = usePostById({ PORT, postIdEdit, TOKEN });
    const { mutate: submitPostUpdate, isLoading: postIsLoading } = useSubmitPostUpdate(PORT, TOKEN, postById);

    const observerRef = useRef(); // Reference for the observer

    const [state, setState] = useState({
        onMind: "",
        image: "",
        imageFiles: [],

    })

    const activeHandleClose = () => setActiveShow(false);

    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const scrollToTop = (val) => {
        window.scrollTo({
            top: val,
            behavior: "smooth"
        });
    };


    const handleDelete = () => {
        deletePost({ statusId }, {
            onSuccess: () => {
                refetch()
                activeHandleClose();
            },
        });
    };


    const LoadPostById = useCallback(async (postIdEdit) => {
        fetchPostById()
        setPostIdEdit(postIdEdit)
        setPostIdEditVisible(true)
        scrollToTop(200)

    }, [])

    const onImageChange = (event) => {

        var fileName = document.getElementById("postImage").value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setSubmitDisabled(false)


                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            toast.error("Only jpg, png and jpeg image files allowed!")
        }
    }


    useEffect(() => {
        refetch()
        // LoadProfile()
        return () => {
            setState({ image: "" });
        }
    }, [refetch])

    const onSubmit = (data) => {
        const imageFile = document.getElementById('postImage').files[0];
        submitPosts({ data, imageFile, Username }, {
            onSuccess: () => {
                reset()
                setState({ image: "" });
                refetch()
                toast.success("You have successfully added a new post");

            }

        });
    };


    const onSubmitUpdate = (data) => {
        const imageFile = document.getElementById('postImage').files.length > 0
            ? document.getElementById('postImage').files[0]
            : null;

        submitPostUpdate({ data, imageFile, Username, postIdEdit, postById }, {
            onSuccess: (response) => {
                console.log("response", response)
                if (response.success) {
                    setState({ image: "" });
                    refetch()
                    reset()
                    setPostIdEditVisible(false)
                    toast.success("Post updated successfully.");
                } else {
                    toast.error("Updated post not successful.");
                    console.log("message", response)
                }
            },
        });
    };



    // Intersection Observer callback
    const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage(); // Fetch the next page when the observer is triggered
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 1.0, // Fully visible before triggering
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasNextPage]); // Re-run when `hasNextPage` changes

    if (isLoading) {
        return <div className="loader-wrapper absolute-sec">
            <div className="loader">

                <div className="loading-svg">
                    <RiUserSearchFill />

                </div>

            </div>

        </div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }



    return (
        <React.Fragment>

            {
                UserTokenId ?
                    <form onSubmit={!postIdEditVisible ? handleSubmit(onSubmit) : handleSubmit(onSubmitUpdate)}>
                        <div className="status-field">
                            <div className={userIdData?.post_status === true ? "status-box border radius" : "d-none"}>
                                <div className="status-area second">
                                    <div className="status-profile">
                                        {

                                            userIdData?.image ?
                                                <img src={`${HOST_PORT}/${userIdData?.image}`} alt="" /> :
                                                <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                        }

                                    </div>
                                    <div className="status-textarea">
                                        <textarea name="description" defaultValue={postIdEditVisible ? (postById?.description) : ""} placeholder={`Whats On Your Mind, ${Username} ?`} className="form-control" {...register('description', { required: false })} required={postIdEditVisible ? "false" : "true"}></textarea>
                                    </div>
                                </div>
                                <div className="uploded-img theme-scroll">
                                    {
                                        !postIdEditVisible || state.image ?
                                            state.image ?
                                                <img className="post1" alt="" aria-hidden="true"
                                                    src={state.image}
                                                /> : "" :
                                            (postById?.image) ?
                                                <img src={`${HOST_PORT}/${postById?.image}`} className="post" alt="" /> : ""
                                    }
                                </div>


                                <div className="upload-file d-flex border-top">

                                    <div className="file-icon">
                                        {
                                            userIdData?.post_status ?
                                                <>
                                                    <FcGallery />
                                                    <input onChange={onImageChange} type="file" class="form-control" id="postImage" />
                                                </>
                                                : ""
                                        }
                                    </div>
                                    <div className="file-submit">
                                        <div className="brown-button sbm-btn text-right">
                                            <button disabled={submitDisabled} type="submit" className="btn">{!postIdEditVisible ? "Post" : "Edit Post"}</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form> : ""
            }

            {postList.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                    {
                        !isLoading ? postList.length ? page.data.map((postdata, i) => (
                            <div className="status-field" key={i + 1}>
                                <div className="status-box border radius">
                                    <div className="post-header">
                                        <Link to={`/profile/${postdata && postdata.user !== undefined ? postdata.user["_id"] : "N/A"}`} className="status-area">
                                            <div className="status-profile">
                                                {
                                                    postdata.userImage ?
                                                        <img src={`${HOST_PORT}/${postdata.userImage}`} alt="" /> :
                                                        <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                                }


                                            </div>
                                            <div className="profile-post">
                                                <h3>{postdata && postdata.user !== undefined ? postdata.user["name"] : "N/A"}</h3>
                                                <p>{postdata && postdata.user !== undefined ? postdata.user["type"] : "N/A"}</p>
                                            </div>
                                        </Link>

                                        <div className="right-post-box">
                                            <span className="date-box">{moment(postdata.createdAt).format("DD/MM/YYYY")}</span>
                                            {

                                                (UserTokenId === String(postdata && postdata.user !== undefined ? postdata.user["_id"] : "N/A")) ?

                                                    <div className="dropdown-box">

                                                        <span className="dropdown-side">
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="btn" id="dropdown-basic">
                                                                    <FaEllipsisV />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => activeHandleShow(postdata._id)}>Delete</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => LoadPostById(postdata._id)}>Edit</Dropdown.Item>



                                                                </Dropdown.Menu>

                                                            </Dropdown>

                                                        </span>
                                                    </div> : <span className="no-data">Post Not Found</span>
                                            }

                                        </div>


                                    </div>
                                    <div className="status-textarea">
                                        <p>{postdata.description}</p>
                                        <img src={`${HOST_PORT}/${postdata.image}`} alt="" />
                                    </div>


                                </div>
                            </div>
                        )) : <span className="no-data text-center d-block">Post Not Found</span> :
                            <div className="loader-wrapper absolute-sec">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <RiLoader2Fill />
                                    </div>
                                </div>
                            </div>

                    }
                </React.Fragment>
            ))}


            {hasNextPage && postList.length && <div ref={observerRef}>
                {hasNextPage && isFetchingNextPage && <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>}
            </div>}





            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to delete post?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={handleDelete}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}
        </React.Fragment >

    )
}


export default FetchPublicPosts;