import { FaImage } from 'react-icons/fa';
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { GiSwordsEmblem } from 'react-icons/gi';
// import Pagination from "react-js-pagination";
import PORTNUMBER_ADMIN from '../../../../api/api'
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap'
import { EventNameForImage } from '../../../../redux/actions/action'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MdDeleteForever } from 'react-icons/md';
import useDeleteEvent from '../../../../hooks/events/useDeleteEvent';
import useEventsList from '../../../../hooks/events/useEventsList';
import useCreateEvent from '../../../../hooks/events/useCreateEvent';

const AddEvents = () => {
    const AdminToken = localStorage.getItem("AdminToken")
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const dispatch = useDispatch()
    const [activeShow, setActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const { register, handleSubmit } = useForm();
    const { mutate: createEvent } = useCreateEvent(PORT_ADMIN, AdminToken);
    const { data: eventList, refetch, isLoading: isLoadingEvents, } = useEventsList(PORT_ADMIN);
    const { mutate: deleteEvent } = useDeleteEvent();

    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)

    }

    const deleteEventFunc = async () => {
        deleteEvent(
            { eventId: statusId, AdminToken, PORT_ADMIN },
            {
                onSuccess: (res) => {
                    // refetch();
                    toast.success("Event is deleted successfully.")
                    activeHandleClose()
                },
                onError: (error) => {
                    toast.error("Failed to delete event.");
                }
            }
        );
    }


    const onSubmit = async (data) => {
        createEvent({ data }, {
            onSuccess: () => {
                refetch()
                toast.success("You have successfully created event");
            }

        });
    }


    useEffect(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');

        }
    }, [])

    return (
        <div className="tables-field" >
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="main-head">
                                <h4>Admin Wine List</h4>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="row">


                            </div>

                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" {...register('title', { required: false })} placeholder="Title" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" {...register('description', { required: false })} placeholder="Description" />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Add Event</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        {/* <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div> */}

                    </form>
                </div>
                <div className="table-order shadow">
                    <table>
                        <tbody>
                            <tr>
                                <th scope="col">S.No</th>
                                <th>Event Name</th>
                                <th>Description</th>
                                <th>Action</th>

                            </tr>
                            {eventList && eventList.length ? eventList.map((event, index) => (

                                //cateArr.push({...wine.category._doc}),
                                <tr key={event._id}>
                                    <th className="s-no" scope="row">{index + 1}</th>
                                    <td>{event.title}</td>
                                    <td>{event.description}</td>

                                    <td className="action-top">
                                        <span onClick={() => activeHandleShow(event._id)} className="icons-action"><MdDeleteForever /></span>
                                        <Link to={`/admin/addEventImages/${event._id}`} onClick={() => dispatch(EventNameForImage(event.title))} className="icons-action"><FaImage /></Link>


                                    </td>

                                </tr>

                            ))

                                : <tr ><td className="no-data">NO WINE FOUND</td></tr>
                            }

                        </tbody>
                    </table>
                    {
                        isLoadingEvents ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />

                                    </div>

                                </div>

                            </div> : ""
                    }
                </div>

            </div>

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to delete event?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={deleteEventFunc}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}
            <ToastContainer />
        </div>
    )
}
export default AddEvents;