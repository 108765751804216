import React from 'react';
import { Accordion } from "react-bootstrap";
import Slider from "react-slick";
import { ImageObj } from '../../../assets/images/images'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { FaBookOpen, FaGraduationCap, FaUniversity, FaUser } from 'react-icons/fa';
// import 'aos/dist/aos.css';
import { GiArrowWings } from 'react-icons/gi';
import IMAGE_HOST from '../../../api/api'
import PORTNUMBER_ADMIN from '../../../api/api'
import AddMemberForm from '../../user/form/addMember/addMemberForm'
import { GiGraduateCap } from 'react-icons/gi';
import { Helmet } from 'react-helmet';
import {politician, gallerySlider, homeBanner, centerSlider, comment, gallary, news} from '../../sliders/sliders'
import useStudentsList from '../../../hooks/students/useStudentsList';
import useEventsList from '../../../hooks/events/useEventsList';
import usePoliticians from '../../../hooks/politicians/usePoliticians';
import useGovtServants from '../../../hooks/govtServants/useGovtServants';
import useYouthIcons from '../../../hooks/youthIcons/useYouthIcons';
import useCongratsList from '../../../hooks/Congrats/useList';

const Home = () => {
    // const PORT = PORTNUMBER.PORTNUMBER;
    // const TOKEN = localStorage.getItem("token")
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    // const AdminToken = localStorage.getItem("AdminToken")

    const { data: allStudents, isLoading : isLoadingStudent, } = useStudentsList(PORT_ADMIN);
    const { data: eventList, isLoading : isLoadingEvents, } = useEventsList(PORT_ADMIN);
    const { data: allPoliticians, isLoading : isLoadingPolitician, } = usePoliticians(PORT_ADMIN);
    const { data: allTopGovtServant, isLoading : isLoadingTopGovtServant, } = useGovtServants(PORT_ADMIN);
    const { data: allYouthIcons, isLoading : isLoadingYouthIcons, } = useYouthIcons(PORT_ADMIN);
    const { data: allCongrats, isLoading : isLoadingCongrats, } = useCongratsList(PORT_ADMIN);




    const { t } = useTranslation();




    return (
        <React.Fragment>

            <Helmet>
                <title>Koli Samaj Vikash Sanstha - Empowering Education & Community Support</title>
                <meta name="description" content="Koli Samaj Vikash Sanstha is dedicated to promoting education within the Koli community and supporting underprivileged individuals. Join us in our mission to make a difference." />
                <meta name="keywords" content="Koli Samaj, Koli Samaj Vikash Sanstha, education awareness, community support, Raam Naath Kovind, Jhalkari Bai, Goutam Buddha, Kanhoji Angre, Yashwant Rao Mukne, Maya Mahamaya, Raja Mandhata, Tanaji Malusare, Kunwarjibhai Bavaliya, Beena Mahawar, Harsh Mahawar, Harendra Mahawar IPS, top Koli students, Koli student achievements" />
                <meta name="author" content="Mahendra Koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/" />
            </Helmet>
            <section className="home">
                <div className="home-crousole">
                    <Slider {...homeBanner}>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.Tanhaji})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn" data-aos="zoom-in"

                                                data-aos-duration="1000">
                                                <h2>{t('TanaJi.Name')}</h2>
                                                <p>{t('TanaJi.About')}</p>
                                                <div className="brown-button"> <Link className="btn" to="/tanaji" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                        <div className="item">
                            <div className="carousel-con laxmi" style={{ backgroundImage: `url(${ImageObj.Laxmi})` }}>
                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn" data-aos="fade-left">
                                                <h2>{t('JhalkariBai.Name')}</h2>
                                                <p>{t('JhalkariBai.About')}</p>
                                                <div className="brown-button"> <Link className="btn" to="/jhalkariBai" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.Yashwant})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn">
                                                <h2>{t('YashwantRao.Name')}</h2>
                                                <p>{t('YashwantRao.About')}</p>
                                                <div className="brown-button"> <Link className="btn" to="/yashwantRaoMukne" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.Mandhata})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn">

                                                <h2>{t('RajaMandhata.Name')}</h2>
                                                <p>{t('RajaMandhata.About')}</p>
                                                <div className="brown-button"> <Link className="btn" to="/rajaMandhata" >{t('knowMore.head')}</Link></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.GoutamBuddha})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn">
                                                <h2>{t('GoutamBuddha.Name')}</h2>
                                                <p>{t('GoutamBuddha.About')} </p>
                                                <div className="brown-button"> <Link className="btn" to="/goutamBuddha" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.Mahamaya})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn">
                                                <h2>{t('MayaMahamaya.Name')}</h2>
                                                <p>{t('MayaMahamaya.About')} </p>
                                                <div className="brown-button"> <Link className="btn" to="/mayadevi" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-con" style={{ backgroundImage: `url(${ImageObj.Kanhoji})` }}>

                                <div className="container">
                                    <div className="col-sm-7">
                                        <div className="head-flex">
                                            <div className="heading-bn">
                                                <h2>{t('Kanhoji.Name')}</h2>
                                                <p>{t('Kanhoji.About')} </p>
                                                <div className="brown-button"> <Link className="btn" to="/kanhojiAgre" >{t('knowMore.head')}</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section >
            {/* <section className="members pt-100 pb-100">
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="profile-inner">
                                        <div className="profile">
                                            <div className="profile-image">
                                                <img aria-hidden="true" alt=""  src={ImageObj.Teacher1} />
                                            </div>
                                            <div className="about-profile">
                                                <h5>Chuang Pei-Han</h5>
                                                <h6 className="blue">Blockchain Advisor </h6>
                                            </div>
                                            <ul>
                                                <li>Serial entrepreneur in APAC region and blockchain expert.</li>
                                                <li>Currently CEO of Morpheus Labs,
                                                Blockchain Platform as a
                                                  Service Provider.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}
            <section className="ps-timeline-sec">
                {/* <div className="gradiant-box">

                </div> */}
                <div className="container">
                    <div className="col-12">
                        <div className="row col-pd-0">
                            <div className="col-sm-3" data-aos="fade-down">
                                <div className="timeline-main reverse-mobile">
                                    <div className="image-box box-top mobile-big">
                                        <div className="img-inner">
                                            <img aria-hidden="true" alt="" src={ImageObj.SuggIconOne} />
                                        </div>
                                    </div>
                                    <div className="num-circle">
                                        <span>1  </span>
                                        <div className="icon top" > <GiArrowWings /></div>
                                    </div>
                                    <div className="content-box box-bottom last-content">
                                        <p>{t('MassegeTopeople.Imagin')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 " data-aos="fade-up">
                                <div className="timeline-main">
                                    <div className="content-box box-top first-content">
                                        <p>{t('MassegeTopeople.HelpSociety')}</p>
                                    </div>
                                    <div className="num-circle">
                                        <span>2</span>
                                        <div className="icon bottom" > <GiArrowWings /></div>
                                    </div>
                                    <div className="image-box box-bottom">
                                        <div className="img-inner">
                                            <img aria-hidden="true" alt="" src={ImageObj.SuggIconTwo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3" data-aos="fade-down">
                                <div className="timeline-main reverse-mobile">
                                    <div className="image-box box-top mobile-big">
                                        <div className="img-inner">
                                            <img aria-hidden="true" alt="" src={ImageObj.SuggIconThree} />
                                        </div>
                                    </div>
                                    <div className="num-circle">
                                        <span>3</span>
                                        <div className="icon top" > <GiArrowWings /></div>
                                    </div>
                                    <div className="content-box box-bottom last-content">
                                        <p>{t('MassegeTopeople.ChildEducation')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3" data-aos="fade-up">
                                <div className="timeline-main">
                                    <div className="content-box box-top first-content">
                                        <p>{t('MassegeTopeople.United')}</p>
                                    </div>
                                    <div className="num-circle">
                                        <span>4</span>
                                        <div className="icon bottom" > <GiArrowWings /></div>
                                    </div>
                                    <div className="image-box box-bottom">
                                        <div className="img-inner">
                                            <img aria-hidden="true" alt="" src={ImageObj.SuggIconFour} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="bg-banner ramnath-banner" style={{ backgroundImage: `url(${ImageObj.RaamNath})` }}>
                <div className="container">
                    <div className="head-flex-kovind" data-aos="zoom-in">
                        <div className="heading-bn mt-0"  >

                            <h2>{t('RaamNaath.Name')}</h2>
                            <p>{t('RaamNaath.About')}</p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="resume believe-top cro">
                <div className="container">
                    <div className="row">

                        <div className="col-md-7">
                            <div className="home-left new second">
                                <div className="brown-head org mb-0">
                                    <h2>{t('Organization.Title')}</h2>
                                    <h1 >Koli Samaj Vikash Sanstha</h1>
                                </div>
                            </div>
                            <div className="blieve-sec height-scroll">
                                <Accordion data-aos="fade-right"
                                    data-aos-offset="300"
                                    data-aos-easing="ease-in-sine" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" >
                                        <Accordion.Header>

                                            <div className="believ-box active">
                                                <div className="box-one">
                                                    <i className="fa fa-check mr-2" aria-hidden="true" />
                                                </div>
                                                <div className="box-two">
                                                    <h2>{t('Organization.BOX_1.Head')}</h2>
                                                    <p>{t('Organization.BOX_1.Description')}</p>
                                                </div>
                                            </div>

                                        </Accordion.Header>

                                        <Accordion.Body>{t('Organization.BOX_1.Summary')}</Accordion.Body>

                                    </Accordion.Item>


                                    <Accordion.Item eventKey="1" >
                                        <Accordion.Header>
                                            <div className="believ-box" >
                                                <div className="box-one">
                                                    <i className="fa fa-check mr-2" aria-hidden="true" />
                                                </div>
                                                <div className="box-two">
                                                    <h2>{t('Organization.BOX_2.Head')}</h2>
                                                    <p>{t('Organization.BOX_2.Description')}</p>
                                                </div>
                                            </div>

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="list-need">
                                                <ol>
                                                    <li>{t('Organization.BOX_2.Summary.One')}</li>
                                                    <li>{t('Organization.BOX_2.Summary.Two')}</li>
                                                    <li>{t('Organization.BOX_2.Summary.Three')}</li>
                                                    <li>{t('Organization.BOX_2.Summary.Four')}</li>
                                                    <li>{t('Organization.BOX_2.Summary.Five')}</li>
                                                    <li>{t('Organization.BOX_2.Summary.Six')}</li>
                                                </ol>
                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>


                                    <Accordion.Item eventKey="2" >
                                        <Accordion.Header>
                                            <div className="believ-box active">
                                                <div className="box-one">
                                                    <i className="fa fa-check mr-2" aria-hidden="true" />
                                                </div>
                                                <div className="box-two">
                                                    <h2>{t('Organization.BOX_3.Head')}</h2>
                                                    <p>{t('Organization.BOX_3.Description')}</p>

                                                </div>
                                            </div>
                                        </Accordion.Header>


                                        <Accordion.Body>
                                            <div className="list-need">
                                                <ol>
                                                    <li>{t('Organization.BOX_3.Summary.One')}</li>
                                                    <li>{t('Organization.BOX_3.Summary.Two')}</li>
                                                    <li>{t('Organization.BOX_3.Summary.Three')}</li>
                                                    <li>{t('Organization.BOX_3.Summary.Four')}</li>

                                                </ol>
                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3" >
                                        <Accordion.Header>
                                            <div className="believ-box active">
                                                <div className="box-one">
                                                    <i className="fa fa-check mr-2" aria-hidden="true" />
                                                </div>
                                                <div className="box-two">
                                                    <h2>{t('Organization.BOX_4.Head')}</h2>
                                                    <p>{t('Organization.BOX_4.Description')}</p>

                                                </div>
                                            </div>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <p>{t('Organization.BOX_4.Summary')}</p>
                                        </Accordion.Body>

                                    </Accordion.Item>

                                    <Accordion.Item eventKey="4" >
                                        <Accordion.Header>
                                            <div className="believ-box active">
                                                <div className="box-one">
                                                    <i className="fa fa-check mr-2" aria-hidden="true" />
                                                </div>
                                                <div className="box-two">
                                                    <h2>{t('Organization.BOX_5.Head')}</h2>
                                                    <p>{t('Organization.BOX_5.Description')}</p>

                                                </div>
                                            </div>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <p>{t('Organization.BOX_5.Summary')}</p>
                                            <p>{t('Organization.BOX_5.Summary2')}</p>

                                        </Accordion.Body>

                                    </Accordion.Item>

                                </Accordion>

                            </div>
                        </div>
                        <div className="col-md-5" data-aos="flip-left">
                            <div className="image-points engage">
                                <div className="right-image">
                                    <img alt="koli community" aria-hidden="true" src={ImageObj.Communitytree} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="circle-yellow">
                </div>
            </section>
            <section className="left-images sec padding bg-shape">


                <div className="back-color">
                    <div className="container">
                        <div className="slide-nav full-silder">
                            <Slider {...politician}>
                                                          
                            {!isLoadingPolitician ? allPoliticians.map((politician, i) => (
                              
                                    <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-left">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={`${HOST_PORT}/${politician.image}`} alt={politician.full_name} />
                                            </div>
                                        </div>
                                        <div className="col-sm-7" data-aos="fade-down"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{politician.full_name}</h2>
                                                    <p>{politician.description}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )):
                               <div className="loader-wrapper absolute-sec">
                                   <div className="loader">
                                       <div className="loading-svg">
                                           <GiGraduateCap />
                                       </div>
                                   </div>
                               </div>
                       }
                                {/* <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-right">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.Ranjeeta} alt="ranjeeta koli" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className="head-flex-kovind" data-aos="fade-up"
                                                data-aos-duration="1000">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('RanjeetaKoli.Name')}</h2>
                                                    <p>{t('RanjeetaKoli.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-right">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.Rajesh} alt="rajesh chudasma" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7" data-aos="fade-up"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('RajeshChudasama.Name')}</h2>
                                                    <p>{t('RajeshChudasama.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-right">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.KuwarJiBavaliya} alt="KuwarJi Bavaliya" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7" data-aos="fade-up"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('KuwarJiBavaliya.Name')}</h2>
                                                    <p>{t('KuwarJiBavaliya.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            {/* beaurocacy */}
            <section className="left-images right-images sec padding bg-shape">


                <div className="back-color">
                    <div className="container">
                        <div className="kovi-slider right-slid-Nav full-silder slide-nav col-rev-mob-row">
                            <Slider {...politician}>
                            {!isLoadingTopGovtServant ? allTopGovtServant.map((govtServant, i) => (
                              
                              <div className="item">
                              <div className="row">
                                 
                                  <div className="col-sm-7" data-aos="fade-down"
                                      data-aos-duration="1000">
                                      <div className="head-flex-kovind">
                                          <div className="heading-bn mt-0">
                                              <h2>{govtServant.full_name}</h2>
                                              <p>{govtServant.description}</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-sm-5" data-aos="fade-left">
                                      <div className="design-image">
                                          <img aria-hidden="true" src={`${HOST_PORT}/${govtServant.image}`} alt={politician.full_name} />
                                      </div>
                                  </div>

                              </div>
                          </div>
                      )):
                         <div className="loader-wrapper absolute-sec">
                             <div className="loader">
                                 <div className="loading-svg">
                                     <GiGraduateCap />
                                 </div>
                             </div>
                         </div>
                 }
                                {/* <div className="item">
                                    <div className="row">

                                        <div className="col-sm-7">
                                            <div className="head-flex-kovind" data-aos="fade-down"
                                                data-aos-duration="1000">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('Harendra.Name')}</h2>
                                                    <p>{t('Harendra.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5" data-aos="fade-left">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.HarendraBurea} alt="Harendra mahawar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">

                                        <div className="col-sm-7" data-aos="fade-down"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('BeenaMahawar.Name')}</h2>
                                                    <p>{t('BeenaMahawar.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5" data-aos="fade-left">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.BeenaMahawar} alt="Beena Mahawar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">

                                        <div className="col-sm-7" data-aos="fade-down"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('HarshMahawar.Name')}</h2>
                                                    <p>{t('HarshMahawar.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5" data-aos="fade-left">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.HarshMahawar} alt="Harsh Mahawar" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


            <section className="left-images sec padding bg-shape">


                <div className="back-color">
                    <div className="container">
                        <div className="slide-nav full-silder">
                            <Slider {...politician}>

                            {!isLoadingYouthIcons ? allYouthIcons.map((youthIcon, i) => (
                              
                              <div className="item">
                              <div className="row">
                                  <div className="col-sm-5" data-aos="fade-left">
                                      <div className="design-image">
                                          <img aria-hidden="true" src={`${HOST_PORT}/${youthIcon.image}`} alt={youthIcon.full_name} />
                                      </div>
                                  </div>
                                  <div className="col-sm-7" data-aos="fade-down"
                                      data-aos-duration="1000">
                                      <div className="head-flex-kovind">
                                          <div className="heading-bn mt-0">
                                              <h2>{youthIcon.full_name}</h2>
                                              <p>{youthIcon.description}</p>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      )):
                         <div className="loader-wrapper absolute-sec">
                             <div className="loader">
                                 <div className="loading-svg">
                                     <GiGraduateCap />
                                 </div>
                             </div>
                         </div>
                 }
                                {/* <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-left">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.ErKhemChand} alt="Er KhemChand" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7" data-aos="fade-down"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('ErKhemChand.Name')}</h2>
                                                    <p>{t('ErKhemChand.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-sm-5" data-aos="fade-right">
                                            <div className="design-image">
                                                <img aria-hidden="true" src={ImageObj.ErKhemChand} alt="Er KhemChand" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7" data-aos="fade-up"
                                            data-aos-duration="1000">
                                            <div className="head-flex-kovind">
                                                <div className="heading-bn mt-0">
                                                    <h2>{t('ErKhemChand.Name')}</h2>
                                                    <p>{t('ErKhemChand.Summary')}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>


            {/* top studend start */}

            <section className="testimonials-top top-student">
                <div className="container">
                    <Slider {...centerSlider}>

                        {

                            !isLoadingStudent ? allStudents?.map((student, i) => (
                                <div key={i + 1} className="item " data-aos="flip-right" >
                                    <div className="testimonials-main">
                                        <div className="testimonials shadow">
                                            <div className="test-img">

                                                <img aria-hidden="true" src={`${HOST_PORT}/${student?.image}`} alt="koli top students" ></img>:

                                            </div>
                                            <div className="test-con text-center">
                                                <ul>
                                                    <li>Name</li>
                                                    <li>{student?.full_name}</li>
                                                    <li>Father Name</li>
                                                    <li>{student?.father_name}</li>
                                                    <li>Standard</li>
                                                    <li>{student?.class}</li>
                                                    <li>Percentage</li>
                                                    <li>{student?.percentage}%</li>
                                                    <li>Year</li>
                                                    <li>{student?.studentid}</li>
                                                    <li>School</li>
                                                    <li>{student?.school}</li>
                                                    <li>Address</li>
                                                    <li>{student?.address}</li>

                                                </ul>
                                            </div>
                                            <div className="social-new">
                                                <div className="badge-box">
                                                    <span className="badge"><FaBookOpen /></span>
                                                    <span className="badge"><FaGraduationCap /></span>
                                                    <span className="badge"><FaUniversity /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) :
                                <div className="loader-wrapper absolute-sec">
                                    <div className="loader">
                                        <div className="loading-svg">
                                            <GiGraduateCap />
                                        </div>
                                    </div>
                                </div>
                        }




                    </Slider>
                </div>
            </section>
            {/* top student end */}


            {/* gallary css start */}
            <section className="gallery-box">

                <Slider {...gallerySlider} className="top-gallery-slider" >
                    {!isLoadingEvents ? eventList?.map((event, i) => (
                        <div className="item" key={i + 1}>
                            <div className="brown-head new text-center" data-aos="fade-down"
                                data-aos-duration="1000">
                                <p>{t('HomeHeadings.Gallary.First')}</p>
                                <h4>{event.title}</h4>
                                <h6>{event.description}</h6>
                            </div>
                            <div className="gallry-slider" data-aos="fade-up"
                                data-aos-duration="1000">
                                <Slider {...gallary} className="inner-gallery-slider" >

                                    {

                                        event.images.map((image, i) => (
                                            <div className="item" key={i + 1}>
                                                <div className="box">
                                                    {image === undefined ?
                                                        <img aria-hidden="true" src={ImageObj.Teacher1} alt="koli_member" /> :
                                                        <img src={`${HOST_PORT}/${image}`} alt="koli samaj program" />
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }



                                </Slider>
                            </div>
                        </div>
                    )):  <div className="loader-wrapper absolute-sec">
                    <div className="loader">
                        <div className="loading-svg">
                            <GiGraduateCap />
                        </div>
                    </div>
                </div>

                    }
                </Slider>

            </section>

            {/* gallary css end */}

            {/*news slider  */}
            <section className="top-news-history">
                <div className="gradiant-box"></div>

                <div className="news-section">
                    <div className="brown-head new text-center" data-aos="fade-down"
                        data-aos-duration="1000">
                        <p>{t('HomeHeadings.News.First')}</p>
                        <h4>{t('HomeHeadings.News.Second')}</h4>
                        <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                            ut labore et dolore magna aliquyam erat, sed diam</h6>
                    </div>
                    <div className="news-slider" data-aos="fade-up"
                        data-aos-duration="1000">
                        <Slider {...news}>
                            <div className="item">
                                <div className="news-box">
                                    <img aria-hidden="true" alt="koli samaj news" src={ImageObj.News2} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="news-box">
                                    <img aria-hidden="true" alt="koli samaj news" src={ImageObj.News3} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="news-box">
                                    <img aria-hidden="true" alt="koli samaj news" src={ImageObj.News4} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="news-box">
                                    <img aria-hidden="true" alt="koli samaj news" src={ImageObj.News5} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="news-box">
                                    <img aria-hidden="true" alt="koli samaj news" src={ImageObj.News6} />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

                {/* news slider end */}

                <div className="history section-padding">
                    <div className="container">
                        <div className="brown-head new text-center" data-aos="fade-down"
                            data-aos-duration="1000">
                            <p>{t('HomeHeadings.HistoryKoli.First')}</p>
                            <h4>{t('HomeHeadings.HistoryKoli.Second')}</h4>
                            <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                                ut labore et dolore magna aliquyam erat, sed diam</h6>
                        </div>

                        <div className="row" data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/rajaMandhata" className="history-box">
                                    <img aria-hidden="true" alt="raja mandhata" src={ImageObj.HisNew1} />
                                </Link>
                            </div>
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/jhalkariBai" className="history-box">
                                    <img aria-hidden="true" alt="jhalkari bai koli" src={ImageObj.His2} />
                                </Link>
                            </div>
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/goutamBuddha" className="history-box">
                                    <img aria-hidden="true" alt="goutam buddha" src={ImageObj.His3} />
                                </Link>
                            </div>
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/mayadevi" className="history-box">
                                    <img aria-hidden="true" alt="maya mahamaya" src={ImageObj.His4} />
                                </Link>
                            </div>
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/tanaji" className="history-box">
                                    <img aria-hidden="true" alt="tanaji malusare" src={ImageObj.His5} />
                                </Link>
                            </div>
                            <div className="col-lg-2 col-sm-3 his-box">
                                <Link to="/kanhojiAgre" className="history-box">
                                    <img aria-hidden="true" alt="kanhoji angre" src={ImageObj.His6} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="get-in-touch touch-bg sec padding ">
                <div className="container ">
                <AddMemberForm />
                </div>
            </section>

            {/* comments sec start */}
            {/* <div className="comments">
                <div className="container">
                    <div className="comment-slider">
                        <Slider {...comment}>
                            <div className="item" data-aos="flip-left">
                                <div className="quotation-box">
                                    <div className="qt-pad">
                                        <div className="quote-img">
                                            <img aria-hidden="true" alt="" src={ImageObj.Qoute_1} />
                                        </div>
                                    </div>
                                    <div className="box-quote">
                                        <h4>Consult and advise clients on strategic culture, business, and talent, topics to advance objectives within an organization.</h4>
                                    </div>
                                    <div className="quote-user">
                                        <div className="user-image">
                                            <img aria-hidden="true" alt="" src={ImageObj.Teacher1} />
                                        </div>
                                        <div className="user-con">
                                            <h4>Jacob Hope</h4>
                                            <p>Product Designer at Design Studio</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item" data-aos="flip-left">
                                <div className="quotation-box">
                                    <div className="qt-pad">
                                        <div className="quote-img">
                                            <img aria-hidden="true" alt="" src={ImageObj.Qoute_2} />
                                        </div>
                                    </div>
                                    <div className="box-quote">
                                        <h4>Consult and advise clients on strategic culture, business, and talent, topics to advance objectives within an organization.</h4>
                                    </div>
                                    <div className="quote-user">
                                        <div className="user-image">
                                            <img aria-hidden="true" alt="" src={ImageObj.Teacher1} />
                                        </div>
                                        <div className="user-con">
                                            <h4>Jacob Hope</h4>
                                            <p>Product Designer at Design Studio</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div> */}

            <section className="testimonials-top congrats-top donation">
                <div className="container">
                    <Slider {...comment}>
                        {
                            !isLoadingCongrats ? allCongrats?.map((congrats)=>(
                                <div className="item">
                                <div className="testimonials-main">
                                    <div className="testimonials congrat">
                                        <div className="test-img">
                                            <img aria-hidden="true" src={`${HOST_PORT}/${congrats.image}`} alt={congrats.heading} />
                                        </div>
                                        <div className="test-con text-center">
                                            <h5>{congrats.heading}</h5>
                                            <p>{congrats.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )):
                            <div className="loader-wrapper absolute-sec">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <FaUser />
                                    </div>
                                </div>
                            </div>
                        }
                       
                    </Slider>
                </div>
            </section>
            {/* comments sec end */}



        </React.Fragment>
    )
}


export default Home