import React, { useEffect, useState, useCallback } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import { Tab, Row, Col } from 'react-bootstrap'
import History from '../allHistory/history'
import SeaThief from '../allHistory/sea-thife'
import Wars from '../allHistory/wars'
import Participation from '../allHistory/participation'
import RajPat from '../allHistory/rajpat'
import SubCast from '../allHistory/sub-caste'
import Tribe from '../allHistory/tribe'
import Venerable from '../allHistory/venerable'
import Famouse from '../allHistory/famous'
import {Helmet} from 'react-helmet'


// list images start
import { ImageObj } from '../../../assets/images/images'
// list images end
const MODAL_OPEN_CLASS = "history-bg";










const AllHistory = () => {


    const useToggle = (initialState = false) => {
        const [state, setState] = useState(initialState);
        const toggle = useCallback(() => setState(state => !state), []);
        return [state, toggle]
    }

    const [isOn, toggleIsOn] = useToggle(!true);

    useEffect(() => {
        document.body.classList.add(MODAL_OPEN_CLASS);
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS);
        }
    }, [])



    return (
        <React.Fragment>
        <Helmet>
            <title>History of Koli Samaj - A Rich Heritage</title>
            <meta name="description" content="Explore the rich history of Koli Samaj, dating back to the fifteenth century. Discover the Koli jagirdars of the Gujarat Sultanate and the old Kshatriya roots of the Koli caste in Maharashtra. Learn about their role as soldiers and their significant contributions to the military capabilities of kings and maharajas in Gujarat and Maharashtra." />
            <meta name="keywords" content="Koli history, Koli Samaj history, Gujarat Koli, Maharashtra Koli, Koli jagirdars, Koli warriors, Koli caste, historical Koli figures, Govind Das Ram Das, Thakor Mansa Singh Khant, Kanhoji Angre, Tanaji Malusare, Ram Rao Patil, Thakor Dhan Mer, Sord dynasty, Parmar, Guhilot, Rathor, Makwana, Jhala, Jethwa, Chudasama, Chauhan, Solanki, Dabhi, Baria, Sarvaiya, Vaghela, Ghodasar Jagir, Amaliyara Jagir, Ilol Jagir, Katosan Jagir, Sathamba Jagir, Aghasi, Bhagavant, Chavan, Bhonsle, Buddhiwant, Daji, Dalvi, Gaikwad, Gavali, Jagtap, Kadam, Kedar, Kharar, Khirsagar, Namdev, Pawar, Polevas, Sagar, Sesh Shiva, Salichi, Suryavanshi, Uterecha Chuuwalia, Kuber Das, Ramnath Kovind" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/allHistory" />
        </Helmet>


            <section className="all-his-page ">
                <div className="container">
                    <div className="toggle-top" onClick={toggleIsOn}>
                        <div id="toggle" className={isOn ? 'on' : 'off'}>
                            <div className="one" />
                            <div className="two" />
                            <div className="three" />
                        </div>
                    </div>
                    <div className="all-page-inner scroll-css resp-page">
                        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                            <Row>

                                <ListGroup className={isOn ? "resp-page" : ""}>
                                    <ListGroup.Item action href="#link1">
                                        <div className="item-inner" onClick={toggleIsOn} >
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.HistoryIcon} />
                                            </div>
                                            <h1>कोली इतिहास</h1>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link2">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.ShipIcon} />
                                            </div>
                                            समुंद्री लुटेरे
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link3">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.SwordIcon} />
                                            </div>
                                            विद्रोह एवं लड़ाइयां
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link4">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.ForceIcon} />
                                            </div>
                                            सैन्य भागीदारी
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link5">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.KingIcon} />
                                            </div>
                                            राजपाट
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link6">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.CastIcon} />
                                            </div>
                                            उपजाति
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link7">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.GodIcon} />
                                            </div>
                                            गोत्र
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link8">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.GotraIcon} />
                                            </div>
                                            सन्त महात्मा
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link9">
                                        <div className="item-inner" onClick={toggleIsOn}>
                                            <div className="item-image">
                                                <img aria-hidden="true" alt="" src={ImageObj.FamouseIcon} />
                                            </div>
                                            प्रसिद्ध कोली
                                        </div>
                                    </ListGroup.Item>


                                </ListGroup>

                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="#link1">
                                            <History />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link2">
                                            <SeaThief />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link3">
                                            <Wars />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link4">
                                            <Participation />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link5">
                                            <RajPat />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link6">
                                            <SubCast />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link7">
                                            <Tribe />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link8">
                                            <Venerable />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link9">
                                            <Famouse />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default AllHistory;