import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const createUser = async ({  data, imageFile, type, PORT }) => {
    const fs = new FormData();
    for (const key in data) {
        fs.append(key, data[key]);
    }
    fs.append("image", imageFile);
    fs.append("type", type);

    const response = await axios.post(`${PORT}/user/create`, fs);
    return response.data;
};

const useCreateUser = (PORT) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({  data, imageFile, type }) => createUser({  data, imageFile, type, PORT }),
        onSuccess: () => {
            queryClient.invalidateQueries(['createUser']); // Adjust the query key if needed
        },
    });
};

export default useCreateUser;
