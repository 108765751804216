import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const createEvent = async ({ data, PORT_ADMIN, AdminToken }) => {

    const response = await axios.post(`${PORT_ADMIN}/event/create`, data, {
        headers: { authorization: `Bearer ${AdminToken}` },
    });

    return response.data;
};

const useCreateEvent = (PORT_ADMIN, AdminToken) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ data }) => createEvent({ data, PORT_ADMIN, AdminToken }),
        onSuccess: () => {
            queryClient.invalidateQueries(['createEvent']); // Adjust the query key if needed
        },
    });
};

export default useCreateEvent;
