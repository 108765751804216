import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const createMatrimonial = async ({  data, imageFile, PORT, TOKEN }) => {
    const fs = new FormData();
    for (const key in data) {
        fs.append(key, data[key]);
    }
    fs.append("image", imageFile);

    const response = await axios.post(`${PORT}/user/marriage/create`, fs, { headers: { authorization: `Bearer ${TOKEN}` } });
    return response.data;
};

const useCreateMatrimonial = (PORT, TOKEN) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({  data, imageFile }) => createMatrimonial({  data, imageFile, PORT, TOKEN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['createMatrimonial']); // Adjust the query key if needed
        },
    });
};

export default useCreateMatrimonial;
