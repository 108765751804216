import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchPoliticianProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/politicianAdmin/profile`,
        { politicianAdminId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const usePoliticianProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['politicianProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchPoliticianProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default usePoliticianProfile;
