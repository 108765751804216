import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchYouthIconProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/YouthIconsAdmin/profile`,
        { youthIconsId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const useYouthIconProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['youthIconProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchYouthIconProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useYouthIconProfile;
