import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const submitEditUser = async ({  data, imageFile, id, PORT, TOKEN, userIdData }) => {
    const fs = new FormData();
    fs.append("userId", id);


    for (const key in data) {
        data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key];
        fs.append(key, data[key]);
    }
    

    if (imageFile > 0) {
        const File = document.getElementById('profileImage').files[0];
        fs.append("image", File);
    }

    const response = await axios.post(`${PORT}/user/profile/update`, fs, {
        headers: { authorization: `Bearer ${TOKEN}` }
    });

    return response.data;
};

const useSubmitEditUser = (PORT, TOKEN, userIdData) => {
    return useMutation({
        mutationFn: ({ data, imageFile, id, }) => submitEditUser({  data, imageFile, id, TOKEN, PORT, userIdData }),
    });
};

export default useSubmitEditUser;
