import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const RajaMandhata =()=>{
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
       <Helmet>
            <title>King Mandhata Mandhata - The Vedic Emperor of Ayodhya</title>
            <meta name="description" content="Discover the history of King Mandhata Mandhata, the ancient Vedic emperor of Ayodhya. Known as Prithvipati, Mandhata ruled the earth and is a revered deity among Suryavanshi Kshatriya Kolis. Learn about his reign, his significance in Vedic history, and his legacy in the Ikshvaku dynasty." />
            <meta name="keywords" content="Mandhata Mandhata, King Mandhata, Vedic Emperor, Ayodhya, Suryavanshi Kshatriya Koli, Prithvipati, Ikshvaku Dynasty, Ashwamedha Yagyas, Bindumati, Shashbindu, Muchkund, Ambareesh, Purukutsa" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/rajaMandhata" />
        </Helmet>

        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisMandhata.titleOne')}</h2>
                        <p>{t('HisMandhata.summeryOne')}</p>
                        <p>{t('HisMandhata.summeryTwo')}</p>
                        <p>{t('HisMandhata.summeryThree')}</p>
                        <p>{t('HisMandhata.summeryFour')}</p>
                        <p>{t('HisMandhata.summeryFive')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default RajaMandhata;