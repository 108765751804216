
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm } from 'react-hook-form';
// icons
import { FaCog, FaSearch } from 'react-icons/fa';
import { FaEye, FaBan, FaRegStar } from 'react-icons/fa';
import { BiMessageRoundedCheck, BiMessageRoundedX } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaImage } from 'react-icons/fa';
import PORTNUMBER_ADMIN from '../../../../api/api'
// Import Datepicker
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'
// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import { MdDeleteForever } from 'react-icons/md';
import { usePaginatedUsers } from '../../../../hooks/users/usePaginatedUsers';
import { RiUserSearchFill } from 'react-icons/ri';
import useChangeUserStatus from '../../../../hooks/users/useChangeUserStatus';
import useUserDelete from '../../../../hooks/users/useUserDelete';


const UserManagement = () => {
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")

    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [imageActiveShow, setImageActiveShow] = useState(false);
    const [imageIdPath, setImageIdPath] = useState("");
    const [statusId, setStatusId] = useState();
    // const [Loading, setLoading] = useState(false);
  
    const [searchTerm, setSearchTerm] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordId, setPasswordId] = useState();
    const { data: userList, isLoading, isError, refetch, error, fetchNextPage, isFetchingNextPage, hasNextPage } = usePaginatedUsers(filterData, PORT_ADMIN, AdminToken);
    const { mutate: changeUserStatus} = useChangeUserStatus();
    const { mutate: userDelete} = useUserDelete();


    const { register, handleSubmit, reset } = useForm();

    const handleClose = () => setShow(false);
    const handleClosePw = () => {
        setShowPassword(false)
        setVisibilityNew(true)
        setVisibility(true)
        setVisibilityConfirm(true)
        reset()
    }
    const handleShowPw = (id) => {
        setPasswordId(id)
        setShowPassword(true);
    }


    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }


    const deleteHandleClose = () => setDeleteShow(false);
    const deleteHandleShow = (id) => {
        setDeleteShow(true);
        setDeleteId(id)

    }



    const activeImageHandleClose = () => setImageActiveShow(false);
    const activeImageHandleShow = (statusId) => {
        setImageIdPath(statusId)
        setImageActiveShow(true);
        // setStatusId(id)

    }


    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (statusVal) => {
        setActiveShow(true);
        setStatusId(statusVal)
        console.log("statusVal", statusVal)

    }


    const deleteUser = async () => {
        
        userDelete(
            { userId: deleteId, AdminToken, PORT_ADMIN },
            {
                onSuccess: (res) => {
                    console.log("res", res)
                    refetch();
                    toast.success("User is deleted successfully.")
                    deleteHandleClose()
                },
                onError: (error) => {
                    toast.error("Failed to delete user.");
                }
            }
        );
    }



    const activeUser = async () => {

        changeUserStatus(
            { userId: statusId.userId, status: statusId.status, type: statusId.type, AdminToken, PORT_ADMIN },
            {
                onSuccess: (res) => {
                    console.log("res", res)
                    refetch();
                    if(statusId.type === "status"){
                        toast.success(statusId.status !== "inactive" ? "User is activated successfully.":"User is inactivated successfully." );
                    }else{
                        toast.success(statusId.status !== false ? "User is post authorization activated successfully." : "User is post status inactivated successfully.")
                    }
                    
                    activeHandleClose();
                },
                onError: (error) => {
                    toast.error("Failed to activate user.");
                }
            }
        );
    }


    useEffect(() => {

        refetch()
    }, [refetch])



    const onSubmitNew = async (data) => {
        setFilterData(data)
        console.log("datadatadata", data)
    }


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');

        }
    }, [])


    const observerRef = useRef();


    // Intersection Observer callback
    const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage(); // Fetch the next page when the observer is triggered
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 1.0, // Fully visible before triggering
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasNextPage]); // Re-run when `hasNextPage` changes

    if (isLoading) {
        return <div className="loader-wrapper absolute-sec">
            <div className="loader">

                <div className="loading-svg">
                    <RiUserSearchFill />

                </div>

            </div>

        </div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }






    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New User</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn top-head text-right">
                                {/* <Link to="/dmin/userManagement" className="btn">Back</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmitNew)}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('name', { required: false })} className="form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="email" {...register('email', { required: false })} className="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('mobile', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('type', { required: false })} aria-label="Default select example">
                                            <option className="disabled-value d-none" value=""  >Type</option>
                                            <option value="member">Member</option>
                                            <option value="politician">Politician</option>
                                            <option value="govt">Govt Employee</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} aria-label="Default select example">
                                            <option className="disabled-value d-none" value=""  >All Status</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Search</button>
                                        <button type="reset" onClick={() => setFilterData('')} className="btn">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Registered On</th>
                                <th>Status</th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>


                            {userList.pages.map((page, pageIndex) => (
                                <React.Fragment key={pageIndex}>
                                    {
                                        !isLoading ? userList ? page.data.filter((val) => {
                                            const date = val.createdAt
                                            if (searchTerm === "") {
                                                return val
                                            } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val
                                            } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val
                                            } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val
                                            } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val
                                            } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val
                                            } else {
                                                return false
                                            }


                                        }).map((user, i) => (
                                            <tr key={user._id}>
                                                <td >
                                                    {user.name}
                                                </td>
                                                <td>{user.email}</td>
                                                <td>{user.mobile}</td>
                                                <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                                <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                                </td>
                                                <td className="action-top">
                                                    {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                                    <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                                    <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                                    {
                                                        user.status === "inactive" ? <span onClick={() => activeHandleShow({userId : user._id, status:"active", type:"status",})} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => activeHandleShow({userId : user._id, status:"inactive", type:"status",})} className="icons-action"><FaBan /></span>

                                                    }

                                                    {
                                                        user.post_status === false ? <span onClick={() => activeHandleShow({userId : user._id, status:true, type:"post",})} className="icons-action"><BiMessageRoundedX /></span> : <span onClick={() => activeHandleShow({userId : user._id, status:false, type:"post",})} className="icons-action"><BiMessageRoundedCheck /></span>

                                                    }
                                                    <span onClick={() => activeImageHandleShow(user.identity)} className="icons-action"><FaImage /></span>
                                                    {
                                                        <span onClick={() => deleteHandleShow(user._id)} className="icons-action"><MdDeleteForever /></span>

                                                    }
                                                    <span onClick={() => handleShowPw(user._id)} className="icons-action"><FaCog /></span>


                                                </td>

                                            </tr>

                                        )) : <span className="no-data">No List Found</span>
                                            : <div className="loader-wrapper absolute-sec">
                                                <div className="loader">

                                                    <div className="loading-svg">
                                                        <RiUserSearchFill />

                                                    </div>

                                                </div>

                                            </div>

                                    }
                                </React.Fragment>
                            ))}

                           
                        </tbody>
                      
                    </table  >
                    

                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>
                {hasNextPage && <div ref={observerRef} className="text-center w-100 mt-2">
                                {hasNextPage && isFetchingNextPage && <div className="spinner">
                                    <div className="rect1"></div>
                                    <div className="rect2"></div>
                                    <div className="rect3"></div>
                                    <div className="rect4"></div>
                                    <div className="rect5"></div>
                                </div>}
                            </div>}
            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>
                    {statusId?.type === "status" ? `Are you sure you want to ${statusId?.status !== "inactive" ? "activate" : "inactive"} user?` : `Are you sure you want to ${statusId?.status !== false ? "activate" : "inactive"} post authorization?`}
                    </Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={activeUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

        

            {/* image view user modal */}
            <Modal className="status-modal" animation={false} show={imageActiveShow} onHide={activeImageHandleClose}>
                {/* <Modal.Body> <img src={`${HOST_PORT}/${postById.image}`} alt="" /></Modal.Body> */}
                {imageIdPath}
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" >
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeImageHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* image view user modal */}


            {/* active modal */}
            <Modal className="status-modal" animation={false} show={deleteShow} onHide={deleteHandleClose}>

                <Modal.Body>Are you sure you want to delete User?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={deleteUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={deleteHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}


            {/* change edit */}
            <div className="modal-edit">
                <Modal show={showPassword} onHide={handleClosePw} className="edit-content">

                    <div className="max-new-second max-margin">
                        <div className="main-theme bg-theme border shadow">
                            <Modal.Header closeButton>
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Change Password</h2>
                                    </div>
                                </div>
                            </Modal.Header>
                            <section className="select-state">
                                <div className="state-bor">
                                    <div className="log-in otp-num">

                                        <div className="select-box-top pr-0">
                                            <div className="input-box">
                                                {/* <form onSubmit={handleSubmit(changePassword)}>
                                                    <div className="col-12 p-0">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group icon-position">
                                                                    <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Current Password" required />
                                                                    {
                                                                        visibility ?
                                                                            <span className="icon" onClick={() => pwVisibility(false)}>
                                                                                <FaEye />
                                                                            </span> :
                                                                            <span className="icon" onClick={() => pwVisibility(true)}>
                                                                                <FaEyeSlash />
                                                                            </span>
                                                                    }
                                                                    <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group icon-position">
                                                                    <input type={visibilityConfirm ? "password" : "text"} className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm Password" required />
                                                                    {
                                                                        visibilityConfirm ?
                                                                            <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                                <FaEye />
                                                                            </span> :
                                                                            <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                                <FaEyeSlash />
                                                                            </span>
                                                                    }
                                                                    <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                                                </div>
                                                            </div>

                                                            <Modal.Footer>
                                                                <div className="theme-btn btn-group-right">
                                                                    <Button type="submit" className="btn" variant="secondary" >
                                                                        Update
                                                                    </Button>
                                                                    <Button type="reset" className="btn" variant="primary" onClick={handleClosePw}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </Modal.Footer>
                                                        </div>
                                                    </div>
                                                </form> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </Modal>
            </div>
            <ToastContainer />
        </div>
    )
}
export default UserManagement;