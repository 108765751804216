
import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm } from 'react-hook-form';
import { GiSwordsEmblem } from 'react-icons/gi';


// icons
import { FaSearch } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import PORTNUMBER_ADMIN from '../../../../api/api'
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'
// react bootstrap
import { Modal, Button } from 'react-bootstrap'

import usePoliticians from '../../../../hooks/politicians/usePoliticians';
import useSubmitPolitician from '../../../../hooks/politicians/useSubmitPolitician';
import useDeletePolitician from '../../../../hooks/politicians/useDeletePolitician';


const TopPoliticians = () => {
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")
    const { data: allPoliticians, isLoading, refetch } = usePoliticians(PORT_ADMIN);
    const { mutate: submitPolitician } = useSubmitPolitician(PORT_ADMIN, AdminToken);
    const { mutate: deletePolitician } = useDeletePolitician(PORT_ADMIN, AdminToken);
    const { register, handleSubmit } = useForm();
    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(()=>{
        refetch()
    },[])

    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const handleDelete = () => {
        deletePolitician({ statusId }, {
            onSuccess: () => {
                activeHandleClose();
            },
        });
    };


    const onSubmit = (data) => {
        const imageFile = document.getElementById('politicianImage').files[0];
        submitPolitician({ data, imageFile }, {
            onSuccess: () => {
                refetch()
               toast.success("You have successfully added a new politician");
            }
        });
    };

    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');
        }
    }, [])


    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Politician</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/userManagement" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">

                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input id="politicianImage" name="image" {...register('image', { required: false })} type="file" className="form-control" />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustom01"  {...register('full_name', { required: true })} placeholder=" Name" required />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea type="text" className="form-control" style={{ minHeight: "400px", height: "100px" }} id="validationCustomTwo2" {...register('description', { required: true })} placeholder="Description" required >

                                        </textarea>
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/userManagement" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Description</th>

                            </tr>
                        </thead>
                        <tbody>


                            {!isLoading && allPoliticians ? allPoliticians.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.full_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else {
                                    return false
                                }


                            }).map(politician => (
                                <tr key={politician._id}>
                                    <td >
                                        {politician.full_name}
                                    </td>
                                    <td >
                                        {politician.description}
                                    </td>

                                    <td className="action-top">
                                        <Link to={`/admin/editPoliticians/${politician._id}`} className="icons-action"><FaEdit /></Link>

                                        {
                                            <span onClick={() => activeHandleShow(politician._id)} className="icons-action"><MdDeleteForever /></span>

                                        }
                                    </td>

                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                            }



                        </tbody>
                    </table  >
                    {
                        isLoading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />
                                    </div>
                                </div>
                            </div> : ""
                    }
                </div>

            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to delete politician?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={handleDelete}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}
            <ToastContainer />
        </div>
    )
}
export default TopPoliticians;