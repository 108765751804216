import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchUserProfile = async ({ queryKey }) => {
    const [, { PORT, UserTokenId , TOKEN }] = queryKey;
    const { data } = await axios.post(
        `${PORT}/user/profile`,
        { userId: UserTokenId  },
        { headers: { authorization: `Bearer ${TOKEN}` } }
    );
    return data.data;
};

const useUserProfile = ({ PORT, UserTokenId , TOKEN }) => {
    return useQuery({
        queryKey: ['userProfile', { PORT, UserTokenId , TOKEN }],
        queryFn: fetchUserProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useUserProfile;
