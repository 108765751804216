import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const deleteEvent = async ({ eventId, AdminToken, PORT_ADMIN }) => {
    const response = await axios.post(
        `${PORT_ADMIN}/event/delete`,
        { eventId },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useDeleteEvent = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ eventId, AdminToken, PORT_ADMIN }) => deleteEvent({ eventId, AdminToken, PORT_ADMIN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['deleteEvent']); // Adjust the query key if needed
        },
    });
};

export default useDeleteEvent;
