import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Mayadevi =()=>{
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
       <Helmet>
            <title>Maya Devi History - Mother of Gautam Buddha and the Queen of Kolia</title>
            <meta name="description" content="Discover the history of Maya Devi, also known as Mahamaya, the mother of Gautam Buddha. Learn about her life, her role in the kingdom of Kolia, and her significance in Buddhist history as the queen and mother of Siddhartha Gautama." />
            <meta name="keywords" content="Maya Devi, Mahamaya, Mother of Gautam Buddha, Queen of Kolia, Siddhartha Gautama, Buddhist history, Maharaj Anjan, Maharani Yashodhara, Prajapati Gautami" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/mayadevi" />
        </Helmet>

        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                    <h2>{t('mayaDevi.titleOne')}</h2>
                    <p>{t('mayaDevi.summeryOne')}</p>
                        
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Mayadevi;