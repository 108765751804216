import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchCampProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/camps/profile`,
        { campId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const useCampProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['campProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchCampProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useCampProfile;
