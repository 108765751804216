import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const YashwantRaoMukne =()=>{

    const { t } = useTranslation();


    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
       <Helmet>
            <title>Maharaja Yashwantrao Martandrao Mukne - Reign of the Last Koli Maharaja of Jawhar</title>
            <meta name="description" content="Explore the reign of Maharaja Yashwantrao Martandrao Mukne, the last Maharaja of Jawhar. Learn about his succession to the throne, his contributions to the Koli community, and his historical significance." />
            <meta name="keywords" content="Yashwantrao Mukne, Maharaja of Jawhar, Koli Maharaja, History of Jawhar, Maharaja Patangsah Mukne, Indian Royalty, Koli Heritage" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/yashwantRaoMukne" />
        </Helmet>
        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisMukne.titleOne')}</h2>
                        <p>{t('HisMukne.summeryOne')}</p>
                        <h2>{t('HisMukne.titleTwo')}</h2>
                        <p>{t('HisMukne.summeryTwo')}</p>
                        <p>{t('HisMukne.summeryThree')}</p>
                        <p>{t('HisMukne.summeryFour')}</p>
                        <h2>{t('HisMukne.titleThree')}</h2>
                        <p>{t('HisMukne.summeryFive')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default YashwantRaoMukne;