import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';

const deleteGovtServant = async ({ statusId, PORT_ADMIN, AdminToken }) => {
    const response = await axios.post(`${PORT_ADMIN}/TopGovtServantAdmin/delete`, 
        { govtServantId: statusId }, 
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useDeleteGovtServant = (PORT_ADMIN, AdminToken) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ statusId }) => deleteGovtServant({ statusId, PORT_ADMIN, AdminToken }),
        onSuccess: () => {
            toast.success("Govt Servant is deleted successfully.");
            queryClient.invalidateQueries(['deleteGovtServant']); // Adjust the query key if needed
        },
    });
};

export default useDeleteGovtServant;
