import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchEventImages = async ({ id, AdminToken, PORT_ADMIN }) => {
    const response = await axios.post(
        `${PORT_ADMIN}/event/detail`,
        { eventId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data.data.images;
};

const useEventImages = ({ id, AdminToken, PORT_ADMIN }) => {
    return useQuery({
        queryKey: ['eventImages', { id, AdminToken,PORT_ADMIN}],
        queryFn: () => fetchEventImages({ id, AdminToken,PORT_ADMIN }),
        enabled: !!id && !!AdminToken && !!PORT_ADMIN, // Ensure the query only runs if id and AdminToken are defined
    });
};

export default useEventImages;
