
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";
import UserManagement from "../../components/admin/components/userManagement/userManagement";
import GovtServantManagement from '../../components/admin/components/govtServantManagement/govtServantManagement'
import SideNav from '../../components/admin/sideNav/sidenav'
import HeaderAdmin from '../../components/admin/header/header'
import Politicians from '../../components/admin/components/addPoliticians/addPoliticians'
import EditPoliticians from '../../components/admin/components/addPoliticians/editPoliticians'

import TopGovtServant from '../../components/admin/components/addTopGovtServent/addTopGovtServant'
import EditTopGovtServant from '../../components/admin/components/addTopGovtServent/editTopGovtServant'

import AddYouthIcons from '../../components/admin/components/addYouthIcons/addYouthIcons'
import EditYouthIcons from '../../components/admin/components/addYouthIcons/editYouthIcons'

import TopStudents from '../../components/admin/components/topStudents/topStudents'
import EventImages from '../../components/admin/components/eventImages/addEventImages'
import AddEvents from '../../components/admin/components/eventImages/addEvents'
import SocietyNews from '../../components/admin/components/societyNews/addSocietyNews'
import ContentList from '../../components/admin/components/contentMangement/contentList/contentList'
import EditContent from '../../components/admin/components/contentMangement/editContent/editContent'

// admin login routes
import ResetMailAdmin from '../../components/admin/auth/resetMail/resetMail'
import ResetPasswordAdmin from '../../components/admin/auth/resetPassword/resetPassword'
import ResetPasswordLinkAdmin from '../../components/admin/auth/resetPwLink/resetPasswordLink'
import EditStudents from "../../components/admin/components/topStudents/editStudents";
import AdminLogin from "../../components/admin/auth/adminLogin/adminLogin";
import HelpPeople from "../../components/admin/components/addPoorPeople/addPeople";
import EdithelpPeople from "../../components/admin/components/addPoorPeople/editpeople";
import EditFeedback from "../../components/admin/components/addFeedback/editFeedback";
import FeedBacks from "../../components/admin/components/addFeedback/addFeedback";
import Camps from "../../components/admin/components/addCamp/addCamp";
import EditCamp from "../../components/admin/components/addCamp/editCamp";
import Congrats from "../../components/admin/components/addCongrats/addCongrats";
import EditCongrats from "../../components/admin/components/addCongrats/editCongrats";


const AdminRoutes = ({ isAuthedAdmin, setauth }) => {

    return (

        <Routes>
            <Route path="/admin" element={<SideNav />}>
                <Route index element={<AdminLogin isAuthedAdmin={isAuthedAdmin} />} />
                <Route path="userManagement" element={<UserManagement />} />
                <Route path="resetmail" element={<ResetMailAdmin />} />
                <Route path="ResetPasswordAdmin/:id" element={<ResetPasswordAdmin />} />
                <Route path="resetPasswordLink/:id" element={<ResetPasswordLinkAdmin />} />
                <Route path="govtServantManagement" element={<GovtServantManagement />} />
                <Route path="topStudents" element={<TopStudents />} />
                <Route path="topStudents/:id" element={<EditStudents />} />
                <Route path="addPoliticians" element={<Politicians />} />
                <Route path="editPoliticians/:id" element={<EditPoliticians />} />
                <Route path="addTopGovtServant" element={<TopGovtServant />} />
                <Route path="editTopGovtServant/:id" element={<EditTopGovtServant />} />
                <Route path="addEventImages/:id" element={<EventImages />} />
                <Route path="addYouthIcons" element={<AddYouthIcons />} />
                <Route path="editYouthIcons/:id" element={<EditYouthIcons />} />
                <Route path="addHelpPeople" element={<HelpPeople />} />
                <Route path="edithelpPeople/:id" element={<EdithelpPeople />} />
                <Route path="addFeedback" element={<FeedBacks />} />
                <Route path="editFeedback/:id" element={<EditFeedback />} />
                <Route path="addCamp" element={<Camps />} />
                <Route path="editCamp/:id" element={<EditCamp />} />
                <Route path="addCongrats" element={<Congrats />} />
                <Route path="editCongrats/:id" element={<EditCongrats />} />
                <Route path="addEvents" element={<AddEvents />} />
                <Route path="societyNews" element={<SocietyNews />} />
                <Route path="contentList" element={<ContentList />} />
                <Route path="contentList/:key" element={<EditContent />} />
            <Route path="*" element={<Navigate to="/admin/userManagement" />} />

                

            </Route>
            <Route path="*" element={<Navigate to="/admin" />} />
 
            {/* <Route path="/" element={<Home />} /> */}


        </Routes>

    )
}

export default AdminRoutes;