import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchPeopleProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/helpPeople/profile`,
        { helpPeopleId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const usePeopleProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['peopleProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchPeopleProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default usePeopleProfile;
