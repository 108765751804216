import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const TanaJi =()=>{
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
       <Helmet>
            <title>Tanaji Malusare - Hero of the Maratha Empire | History of Tanaji Malusare</title>
            <meta name="description" content="Learn about Tanaji Malusare, a legendary Maratha Subedar and a key figure in Chhatrapati Shivaji Maharaj's Maratha Empire. Discover his role in the establishment of Hindavi Swarajya, his bravery at the Battle of Sinhagad, and his legacy as a childhood friend and trusted commander of Shivaji Maharaj." />
            <meta name="keywords" content="Tanaji Malusare, Maratha Empire, Chhatrapati Shivaji Maharaj, Subedar, Killedar, Hindavi Swarajya, Sinhagad Fort, Kondana Fort, Konkan, Maratha History" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/tanaji" />
        </Helmet>

        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisTanaji.titleOne')}</h2>
                        <p>{t('HisTanaji.summeryOne')}</p>
                        <p>{t('HisTanaji.summeryTwo')}</p>
                        <h2>{t('HisTanaji.titleTwo')}</h2>
                        <p>{t('HisTanaji.summeryThree')}</p>
                        <p>{t('HisTanaji.summeryFour')}</p>
                        <p>{t('HisTanaji.summeryFive')}</p>
                        <h2>{t('HisTanaji.titleThree')}</h2>
                        <p>{t('HisTanaji.summerySix')}</p>
                        <h2>{t('HisTanaji.titleFour')}</h2>
                        <p>{t('HisTanaji.summerySeven')}</p>

                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default TanaJi;