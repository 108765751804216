import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const updatePoliticianProfile = async ({ data, imageFile, id, PORT_ADMIN, AdminToken, userIdData }) => {
    const fs = new FormData();
    fs.append("politicianAdminId", id);

    for (const key in data) {
        data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key];
        fs.append(key, data[key]);
    }

    if (imageFile) {
        fs.append("image", imageFile);
    }

    const response = await axios.post(`${PORT_ADMIN}/politicianAdmin/update`, fs, {
        headers: { authorization: `Bearer ${AdminToken}` }
    });

    return response.data;
};

const useUpdatePoliticianProfile = (PORT_ADMIN, AdminToken, userIdData) => {
    return useMutation({
        mutationFn: ({ data, imageFile, id }) => updatePoliticianProfile({ data, imageFile, id, PORT_ADMIN, AdminToken, userIdData }),
    });
};

export default useUpdatePoliticianProfile;
