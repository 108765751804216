
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';

// Function to fetch data with pagination
const fetchUsers = async ({ pageParam = 1, queryKey }) => {


    // const TOKEN = localStorage.getItem("token")
    // const PORT = PORTNUMBER.PORTNUMBER

  // queryKey[1] will contain the customString if provided
  // const customString = queryKey[1];
  // Extract the custom string from queryKey (assuming it's the second element)
  const [_, filter, PORT, TOKEN] = queryKey;
  // const PORT = queryKey[2];
  // const TOKEN = queryKey[3];


  // Determine the endpoint based on whether filter exists and is non-empty
  const endpoint = filter && Object.values(filter).some(val => val) ? `${PORT}/user/filteredList` : `${PORT}/user/list`;
  
  // Construct the params object
  const params = { page: pageParam, limit: 100, ...filter };

  const response = await axios.post(endpoint, params, {
    headers: {
      authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json',
    },
  });
  
  return {
    data: response.data.data.userList,
    nextPage: pageParam + 1, // The next page to load
  };
};

// Custom hook using `useInfiniteQuery` for lazy loading
export const usePaginatedUsers = (customString, PORT, TOKEN ) => {
  return useInfiniteQuery({
    queryFn: fetchUsers ,
    getNextPageParam: (lastPage) => lastPage.nextPage, // Get the next page parameter
    // Optional configurations
    staleTime: 5000, // Data freshness duration
    cacheTime: 60000, // Cache time
    retry: 2, // Retry attempts
    queryKey: ['users', customString, PORT, TOKEN],
  });
};