import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchGovtServantProfile = async ({ queryKey }) => {
    const [, { PORT_ADMIN, id, AdminToken }] = queryKey;
    const { data } = await axios.post(
        `${PORT_ADMIN}/TopGovtServantAdmin/profile`,
        { govtServantId: id },
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return data.data;
};

const useGovtServantProfile = ({ PORT_ADMIN, id, AdminToken }) => {
    return useQuery({
        queryKey: ['govtServantProfile', { PORT_ADMIN, id, AdminToken }],
        queryFn: fetchGovtServantProfile,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useGovtServantProfile;
