import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchUserMatrimonial = async ({ queryKey }) => {
    const [, { PORT, id , TOKEN }] = queryKey;
    const { data } = await axios.post(
        `${PORT}/user/marriage/detail`,
        { marriageId: id  },
        { headers: { authorization: `Bearer ${TOKEN}` } }
    );
    return data.data;
};

const useMatrimonialProfile = ({ PORT, id , TOKEN }) => {
    return useQuery({
        queryKey: ['userProfile', { PORT, id , TOKEN }],
        queryFn: fetchUserMatrimonial,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useMatrimonialProfile;
