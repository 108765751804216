import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import loginAPI from "../../api/api";


const loginUser = async ({ username, password }) => {
    const response = await axios.post(loginAPI.LOGIN_API, { username, password });
    return response.data;
};


const useLoginUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({  username, password }) => loginUser({  username, password }),
        onSuccess: () => {
            queryClient.invalidateQueries(['loginUser']); // Adjust the query key if needed
        },
    });
};


export default useLoginUser;
