import React from 'react'
import { ImageObj } from '../../../assets/images/images'
import { Tabs, Tab } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import FetchPublicPosts from '../../../common/user/fetchPublicPosts/fetchPublicPosts'

const PublicPost = () => {




    return (
        <React.Fragment>
            <Helmet>
            <title>Koli Samaj Post Page - Latest Updates and Community News</title>
            <meta name="description" content="Stay updated with the latest posts and news from Koli Samaj. Explore community updates, events, and important announcements on our post page." />
            <meta name="keywords" content="Koli Samaj news, Koli Samaj posts, Koli community updates, Koli Samaj announcements, Koli Samaj events" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/publicPost" />
        </Helmet>

            <section className="committee public-post-top">
                <div className="container">
                    <div className="committee-profile">
                        <div className="cover-image" style={{ backgroundImage: `url(${ImageObj.SansthaTitle})` }}>
                            {/* <img aria-hidden="true" src={ImageObj.SansthaTitle} alt="Committee" /> */}

                        </div>
                        <div className="profile public-profile mt-0">
                            <div className="row">
                                {/* <div className="col-md-4 col-sm-5">

                                <div className="profile-img">
                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt=""  />
                                </div>
                                <div className="user-info border radius">
                                    <ul>
                                        {userIdData.name ?
                                            <li>
                                                <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.WorkIcon} /></span>
                                                <p>Name: <span>{userIdData.name}</span></p>
                                            </li> : ""
                                        }
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.WorkIcon} /></span>
                                            <p>{userIdData.email} </p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.StudiesIcon} /></span>
                                            <p> Studied at <span> Raj rishi college alwar</span></p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.StudiesIcon} /></span>
                                            <p>Went to new national sr sec school,chikani alwar</p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.LivesInIcon} /></span>
                                            <p> Lives in <span> Jaipur, Rajasthan</span></p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.LocationIcon} /></span>
                                            <p> From <span> Alwar City, Rajasthan, India</span></p>
                                        </li>
                                    </ul>
                                </div>


                            </div> */}
                                <div className="col-md-12 col-sm-12">
                                    <div className="main-profile-tab public-post">

                                        <div className="profile-tab tab-design">
                                            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                                <Tab eventKey="home" title="Home" >
                                                   <FetchPublicPosts/>


                                                </Tab>
                                                <Tab disabled eventKey="profile" title="Profile">
                                                    frfefrff
                                                </Tab>
                                                <Tab disabled eventKey="contact" title="Contact">
                                                    frfefrff
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
            </section>
        </React.Fragment>

    )
}


export default PublicPost;