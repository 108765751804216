import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';

const deleteYouthIcon = async ({ statusId, PORT_ADMIN, AdminToken }) => {
    const response = await axios.post(`${PORT_ADMIN}/YouthIconsAdmin/delete`, 
        { youthIconsId: statusId }, 
        { headers: { authorization: `Bearer ${AdminToken}` } }
    );
    return response.data;
};

const useDeleteYouthIcon = (PORT_ADMIN, AdminToken) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ statusId }) => deleteYouthIcon({ statusId, PORT_ADMIN, AdminToken }),
        onSuccess: () => {
            toast.success("Yoth Icon is deleted successfully.");
            queryClient.invalidateQueries(['deleteYouthIcon']); // Adjust the query key if needed
        },
    });
};

export default useDeleteYouthIcon;
