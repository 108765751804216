import React, { useState, useEffect, useCallback } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import axios from 'axios'
import PORTNUMBER from '../../../../api/api'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Pagination from "react-js-pagination";
import { Helmet } from 'react-helmet'



const ActiveDonors = () => {
    const PORT = PORTNUMBER.PORTNUMBER;
    const [allUsers, setAllUsers] = useState(["not"]);
    const TOKEN = localStorage.getItem("token")
    const { t } = useTranslation();

    const [Count, setCount] = useState(1);
    const [activePage, setActivePage] = useState()
    // const [loading, setLoading] = useState(false);


    const loadUser = useCallback(async (pageNumber) => {
        await axios.post(`${PORT}/user/list`, { page: pageNumber }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setAllUsers(res.data.data.userList);
                setCount(res.data.data.totalUser);

            })
    }, [PORT, TOKEN])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        // setIndexPage(pageNumber)
        loadUser(pageNumber)
        // setLoading(false);
    }
    useEffect(() => {
        loadUser();

    }, [loadUser]);



    return (
        <React.Fragment>
          <Helmet>
            <title>Koli Samaj Donors - Honoring Contributors to Our Community</title>
            <meta name="description" content="Meet the generous donors and social workers who have made significant contributions to the advancement of Koli society. Learn about their impactful support and efforts to uplift the community." />
            <meta name="keywords" content="Koli Samaj donors, Koli community supporters, Koli social workers, Koli Samaj contributions, Koli society philanthropists, Koli Samaj active donors" />
            <meta name="author" content="Mahendra Koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/activeDonors" />
        </Helmet>

            <section className="home-sec">
                <div className="bg-page" style={{ backgroundImage: `url(${ImageObj.TeamImage})` }}>
                    <div className="container">
                        <div className="col-sm-12">
                            <div className="bg-content">
                                <div className="text-box new">
                                    <h3>{t('MembersList.members')}</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="portfolio  pb-0 sec padding dir">
                <div className="container">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="portfolio-left">
                                    <h4>Why Choose Us</h4>
                                    <h3>We’re not the fastest or cheapest, but we’ll treat you fairly, share some laughs and
                                        work with you to achieve your goals.</h3>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div className="counts mt-0">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>1000</h5>
                                                <p>{t('MembersList.totalMembers')}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>500+</h5>
                                                <p>{t('MembersList.totalEmployee')}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>500</h5>
                                                <p>{t('MembersList.totalPoltician')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-sec pb-0 sec padding dir">
                <div className="container">
                    <div className="brown-head text-center">
                        <p>Out Team</p>
                        <h4>Board of directors</h4>
                    </div>
                    <div className="directors">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="director-box">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.DirectorLongOne} alt="director-img" />
                                            </div>
                                        </div>
                                        <div className="box-content new">
                                            <h5>Unknown</h5>
                                            <h6>Co-Developer</h6>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                voluptua. At vero eos et accusam et justo duo dolores </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" data-aos="fade-right" data-aos-duration="2000">
                                    <div className="director-box">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.DirectorLongTwo} alt="director-img" />
                                            </div>
                                        </div>
                                        <div className="box-content new">
                                            <h5>Unknown</h5>
                                            <h6>Co-Developer</h6>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                voluptua. At vero eos et accusam et justo duo dolores </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="directors-sec sec padding dir">
                <div className="container">
                    <div className="brown-head new text-center">
                        <p>Out Team</p>
                        <h4>Our Awesome team</h4>
                        <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                            ut labore et dolore magna aliquyam erat, sed diam</h6>
                    </div>
                    <div className="directors">
                        <div className="col-sm-12">
                            <div className="row">
                                {allUsers && allUsers.length? allUsers.filter((val) => {
                                    if (String(val && val.type !== undefined ? val.type : "N/A") === "member") {
                                        return val
                                    } else {
                                        return false
                                    }
                                }).map((user, i) => (
                                    <div className="col-lg-3 col-sm-4" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                        <Link to={`/profile/${user._id}`} className="director-box team">
                                            <div className="box-img">
                                                <div className="img-inner">
                                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="director-img" />
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                <h5>{user.name}</h5>
                                                <h6>Co-Developer</h6>
                                            </div>
                                        </Link>
                                    </div>

                                )):<span className="no-data">No List Found</span>
                                }
                            </div>
                        </div>
                        {
                            (Count > 50) ?
                                <div className='pagination-top'>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={Count}
                                        pageRangeDisplayed={10}
                                        onChange={handlePageChange}
                                    />
                                </div> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="directors-sec-last sec padding dir pt-0">
                <div className="container">
                    <div className="brown-head new text-center">
                        <p>Partners</p>
                        <h4>Our Partners</h4>
                        <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                            ut labore et dolore magna aliquyam erat, sed diam</h6>
                    </div>
                    <div className="directors">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="ab-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="urban-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="lettire-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="jk-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default ActiveDonors;