import React, { useEffect, useState } from 'react'
import PORTNUMBER_ADMIN from '../../../../api/api'
import IMAGE_HOST from '../../../../api/api'
import { useParams, Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import { MdDelete } from 'react-icons/md';
import { GiSwordsEmblem } from 'react-icons/gi';
import { useSelector } from 'react-redux'
// react bootstrap
import { Modal, Button, ProgressBar } from 'react-bootstrap'
import useUploadImages from './useUploadImages';
import useEventImages from './useEventImages';
import useRemoveImage from './useRemoveImage';
const EventImages = () => {
  const HOST_PORT = IMAGE_HOST.IMAGE_HOST
  const { id } = useParams()
  const AdminToken = localStorage.getItem("AdminToken")
  const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
  const [buttonVisible, setButtonVisible] = useState(false);
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  const [progress, setProgress] = useState()
  const [progressVisiblity, setProgressVisibility] = useState(null)
  const eventNameForImage = useSelector((state) => state.eventNameReducers)
  const { mutate: uploadImages } = useUploadImages();
  const { data: imagesEvents, refetch, isLoading: isImageLoading } = useEventImages({ id, AdminToken, PORT_ADMIN });
  const { mutate: removeImage } = useRemoveImage();

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id) => {
    setActiveShow(true);
    setStatusId(id)
  }

  const activeUser = async () => {
    removeImage(
      { statusId, id, AdminToken, PORT_ADMIN },
      {
        onSuccess: () => {
          refetch()
          activeHandleClose()
          toast.success("Event image is deleted successfully.");
        },
        onError: () => {
          toast.error("Failed to delete image.");
        },
      }
    );
  }


  const handleImageChange = (e) => {
    if (e.target.files) {
      // setButtonVisible(true)
      onSubmit()

    }
  };


  useEffect(() => {
    refetch()
  }, [refetch])


  const onSubmit = (e) => {
    setProgressVisibility(true);
    setButtonVisible(false);
    const imageFiles = document.getElementById('imagesUpload').files;

    if (imageFiles.length > 0) {
      uploadImages(
        { imageFiles, id, AdminToken, PORT_ADMIN },
        {
          onSuccess: () => {
            refetch();
            toast.success("Wine images are uploaded successfully.");
            setProgressVisibility(false);
            setProgress(null);
            setButtonVisible(true);
          },
          onError: () => {
            toast.error("Failed to upload images.");
            setProgressVisibility(false);
            setButtonVisible(true);
          },
        }
      );
    } else {
      toast.error("Upload at least one image");
      setProgressVisibility(false);
      setButtonVisible(true);
    }
  };

  useEffect(() => {
    document.body.classList.add('remove-home-header', 'admin-dashboard');
    return () => {
      document.body.classList.remove('remove-home-header', 'admin-dashboard');

    }
  }, [])
  return (
    <React.Fragment>

      <div className="col-12">
        <div className="row">
          <div className="col-sm-9">
            <div className="main-head">
              <h4>Wine images for {eventNameForImage}</h4>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="theme-btn text-right top-head">
              <Link to="admin/addEvents" className="btn">Back</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="wine-images shadow">
        <div className="tab-head">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="theme-btn  file-upload-button">
                <input type="file" id="imagesUpload" multiple onChange={handleImageChange} />
                <button className="btn">Upload Images</button>
              </div>
            </div>
            <div className="col-lg-10 col-md-9 col-sm-6">
              <div className="theme-btn progress-bttons ">
                {progressVisiblity ? progress && <button className="btn btn-progress w-100"><ProgressBar now={progress} label={`${progress}%`} /></button> : ""}
                {
                  buttonVisible ? <button type="submit" onClick={(e) => onSubmit(e)} className="btn w-100" >Click To Upload</button> : ''
                }

              </div>
            </div>
          </div>


          <div className="images-box-wine">

            {!isImageLoading ? imagesEvents && imagesEvents.length ?
              <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                {
                  imagesEvents.map((images, i) => (
                    <div className="col mt-0" key={i + 1}>
                      <div className="images-box ">
                        <img src={`${HOST_PORT}/${images}`} alt="" />
                        <span onClick={() => activeHandleShow(images)} className="icons-action"><MdDelete /></span>
                      </div>


                    </div>
                  ))
                }

              </div> :
              <div className="images-box min-height-box">
                <div className="no-data">
                  <h3>No image availabe for this wine.</h3>
                </div>
              </div> :
              <div className="loader-wrapper">
                <div className="loader">
                  {/* <img src="../assets/images/logo-loader.png" alt="" /> */}
                  <div className="loading-svg">
                    <GiSwordsEmblem />

                  </div>

                </div>

              </div>
            }






            {
              isImageLoading ?
                <div className="loader-wrapper">
                  <div className="loader">
                    {/* <img src="../assets/images/logo-loader.png" alt="" /> */}
                    <div className="loading-svg">
                      <GiSwordsEmblem />

                    </div>

                  </div>

                </div> : ""
            }

          </div>
        </div>

        {/* modals */}

        {/* active modal */}
        <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

          <Modal.Body>Are you sure you want to delete this image ?</Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-right">
              <Button variant="secondary"
                onClick={activeUser}
              >
                Yes
              </Button>
              <Button variant="primary" onClick={activeHandleClose}>
                No
              </Button>
            </div>

          </Modal.Footer>
        </Modal>
        {/* active modal end */}

        <ToastContainer />


      </div>
    </React.Fragment>
  )
}

export default EventImages;