import React from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { Tabs, Tab } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import PORTNUMBER from '../../../../api/api'
import { FaUser, FaNetworkWired, FaCity, FaBlackTie, FaMapMarkedAlt, FaChartArea } from 'react-icons/fa';
import { HiOfficeBuilding, HiOutlineUserGroup } from 'react-icons/hi';
import { MdLocationCity } from 'react-icons/md';
import FetchPublicPosts from '../../../../common/user/fetchPublicPosts/fetchPublicPosts'
import { useTranslation } from 'react-i18next';
import IMAGE_HOST from '../../../../api/api'
import useUserProfile from '../../../../hooks/users/useUserProfile'
// import { FaRegUserCircle } from 'react-icons/fa';


const Profile = () => {
    const { id } = useParams()
    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    const { t } = useTranslation();

    const { data: userIdData } = useUserProfile({ PORT, UserTokenId:id, TOKEN });



    return (
        <section className="committee">
            <div className="container">
                <div className="committee-profile">
                    <div className="cover-image" style={{ backgroundImage: `url(${ImageObj.SansthaTitle})` }}>
                        <div className="edit-btn d-mobile-none">
                            {
                                UserTokenId === id ?
                                    <div className="brown-button text-right" >

                                        <Link className="btn" to={`../editMemberForm/${userIdData?._id}`} >Edit Profile</Link>

                                    </div> : ""
                            }

                        </div>
                        {/* <img aria-hidden="true" src={ImageObj.Hands} alt="Committee" /> */}
                    </div>
                    <div className="profile user-profile">
                        <div className="row">
                            <div className="col-md-4 col-sm-5">

                                <div className="profile-img">
                                    {userIdData?.image ?
                                        <img src={`${HOST_PORT}/${userIdData?.image}`} alt="" />
                                        : <img aria-hidden="true" src={ImageObj.UserBlank} alt="koli mambers" />
                                    }
                                </div>
                                <div className="user-info border radius">
                                    <ul>
                                        {userIdData?.name ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p>{t('Member.Name')}: <span>{userIdData?.name}</span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.work && !userIdData?.designation ?
                                            <li>
                                                <span className="info-icon"><FaBlackTie /></span>
                                                <p>{t('Member.Work2')} : <span> {userIdData?.work} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.city ?
                                            <li>
                                                <span className="info-icon"><FaCity /></span>
                                                <p>{t('Member.City')} : <span> {userIdData?.city} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.state ?
                                            <li>
                                                <span className="info-icon"><FaMapMarkedAlt /></span>
                                                <p> {t('Member.State')} : <span>  {userIdData?.state}</span></p>
                                            </li> : ""
                                        }
                                        {/* {userIdData?.full_address ?
                                            <li>
                                                <span className="info-icon"><FaMapMarkedAlt /></span>
                                                <p> {t('Member.Address')} : <span>  {userIdData?.full_address}</span></p>
                                            </li> : ""
                                        } */}
                                        {userIdData?.gender ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p> {t('Member.Gender')} : <span> {userIdData?.gender} </span></p>
                                            </li> : ""
                                        }
                                        {/* {userIdData?.dob ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p> {t('Member.Age')} : <span> {moment(userIdData?.dob).format("DD/MM/YYYY")} </span></p>
                                            </li> : ""
                                        } */}
                                        {userIdData?.designation ?
                                            <li>
                                                <span className="info-icon"><FaNetworkWired /></span>
                                                <p> {t('Servant.Designation')} : <span> {userIdData?.designation} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.office_city ?
                                            <li>
                                                <span className="info-icon"><HiOfficeBuilding /></span>
                                                <p> {t('Servant.OfficeCity')} : <span> {userIdData?.office_city} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.office_address ?
                                            <li>
                                                <span className="info-icon"><HiOfficeBuilding /></span>
                                                <p> {t('Servant.OfficeAddress')} : <span> {userIdData?.office_address} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.office_state ?
                                            <li>
                                                <span className="info-icon"><MdLocationCity /></span>
                                                <p> {t('Servant.OfficeState')} : <span> {userIdData?.office_state} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.politician_area ?
                                            <li>
                                                <span className="info-icon"><FaChartArea /></span>
                                                <p> {t('Politician.PoliticianArea')} : <span> {userIdData?.politician_area} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData?.politician_party ?

                                            <li>
                                                <span className="info-icon"><HiOutlineUserGroup /></span>
                                                <p> {t('Politician.PoliticianParty')} : <span> {userIdData?.politician_party} </span></p>
                                            </li> : ""
                                        }

                                    </ul>

                                </div>
                                <div className="edit-btn d-mobile-block">
                                    {
                                        UserTokenId === id ?
                                            <div className="brown-button text-right" >

                                                <Link className="btn" to={`../editMemberForm/${userIdData?._id}`} >Edit Profile</Link>


                                            </div> : ""
                                    }

                                </div>

                            </div>
                            <div className="col-md-8 col-sm-7">
                                <div className="main-profile-tab ">
                                    <div className="profile-con">
                                        <div className="row">
                                            <div className="col-sm-6 sm-none">
                                                {/* <div className="head text-left">
                                                    <h2>{userIdData?.name}</h2>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="profile-tab tab-design">
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            <Tab eventKey="home" title="Home" >
                                                <FetchPublicPosts />
                                            </Tab>
                                            <Tab disabled eventKey="profile" title="Profile">
                                                profile
                                            </Tab>
                                            <Tab disabled eventKey="contact" title="Contact">
                                                contacts
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Profile;
