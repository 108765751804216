
import CHANGEPASSWORD_API from "../../api/api";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const changePassword = async ({ password, newPassword, TOKEN }) => {
    const response = await axios.post(
        CHANGEPASSWORD_API.CHANGEPASSWORD_API,
        { password, newPassword },
        { headers: { authorization: `Bearer ${TOKEN}` } }
    );
    return response.data;
};

const useChangePassword = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ password, newPassword, TOKEN }) => changePassword({ password, newPassword, TOKEN }),
        onSuccess: () => {
            queryClient.invalidateQueries(['changePassword']); // Adjust the query key if needed
        },
    });
};

export default useChangePassword;
