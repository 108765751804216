import { useQuery } from '@tanstack/react-query';
import axios from 'axios';



const fetchFeedbackList = async ({ queryKey }) => {
    const [, PORT_ADMIN] = queryKey;
    const { data } = await axios.get(`${PORT_ADMIN}/feedbacks/list`);
    return data.data;
};

const useFeedbacksList = (PORT_ADMIN) => {
    return useQuery({
        queryKey: ['feedbacks', PORT_ADMIN],
        queryFn: fetchFeedbackList,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
};

export default useFeedbacksList;