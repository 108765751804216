import React, {useEffect} from "react"
import { useNavigate,useParams } from "react-router-dom"
import { useForm } from "react-hook-form";
import { ToastContainer, toast, } from "react-toastify";
import PORTNUMBER_ADMIN from '../../../../api/api'
import { Link } from 'react-router-dom'
import useCongratsProfile from "../../../../hooks/Congrats/useProfile";
import useUpdateCongratsProfile from "../../../../hooks/Congrats/useUpdate";



const EditCongrats = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const AdminToken = localStorage.getItem("AdminToken")
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const { data: congratsData, refetch } = useCongratsProfile({ PORT_ADMIN, id, AdminToken });
    const { mutate: updateCongratsProfile } = useUpdateCongratsProfile(PORT_ADMIN, AdminToken, congratsData);
    const {register, handleSubmit } = useForm();



    const onSubmit = (data) => {
        const imageFile = document.getElementById('congratsEditImage').files.length > 0 
            ? document.getElementById('congratsEditImage').files[0] 
            : null;
        
            updateCongratsProfile({ data, imageFile, id }, {
            onSuccess: (response) => {
                if (response.success) {
                    refetch()
                   toast.success("Profile updated successfully.");
                    navigate('/admin/addCongrats');
                } else {
                    toast.error("Updated profile not successful.");
                }
            },
        });
    };


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
          document.body.classList.remove('remove-home-header', 'admin-dashboard');
    
        }

      }, [])


  
    return (
        <div className="tables-field">
          <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Edit Congrats</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/addCongrats" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                    <small  className=" form-text d-block text-center mb-4 text-muted"></small>
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                            <div className="col-lg-6">
                                    <div className="form-group">
                                    <input id="congratsEditImage" name="image" {...register('image', { required: false })} type="file" className="form-control" />

                                    </div>
                                </div>
                        
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" name="heading" className="form-control" defaultValue={congratsData?.heading} id="validationCustomedit01"  {...register('heading', { required: false })} placeholder=" Name" required />
                                        <div className="invalid-feedback">
                                            heading required.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" name="location" className="form-control" defaultValue={congratsData?.location} id="validationCustomedit02"  {...register('location', { required: false })} placeholder=" location" required />
                                        <div className="invalid-feedback">
                                            User location required.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea type="text" name="description" defaultValue={congratsData?.description} className="form-control" id="validationCustomTwo3" {...register('description', { required: false })} placeholder="Description" required >

                                        </textarea>
                                        <div className="invalid-feedback">
                                           Please Enter Description
                                        </div>
                                     
                                    </div>
                                </div>
                     
                              
                             
                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/addCongrats" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    
          
            <ToastContainer />
        </div>
    )
}
export default EditCongrats;