import React, { useState } from 'react'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdLogin } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom'
import { loginModalTrue, signUpModalTrue, toasterValue, userLoginTrue } from '../../../redux/actions/action'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import useLoginUser from '../../../hooks/users/useloginUser';



const LoginUser = (props) => {
    const dispatch = useDispatch()
    const [errPass, setErrPass] = useState();
    const [errLogin, setErrLogin] = useState();
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);
    const [visibility, setVisibility] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit } = useForm();

    const { mutate: loginUser, isLoading } = useLoginUser();

    const pathname = location.pathname.split('/')[1];;
  

    const pwVisibility = (value) => {
        setVisibility(value)
    }

    const openSignUp=()=>{
        dispatch(signUpModalTrue(true))
        dispatch(loginModalTrue(false))
    }


    const onSubmit = async (data) => {
        setSpinLoadVisibility(true);
        loginUser(
            { username: data.username, password: data.password },
            {
                onSuccess: (res) => {
                    if (res.success !== true) {
                        setSpinLoadVisibility(false);
                        switch (res.message) {
                            case "Username is not exist!":
                                setErrLogin(res.message);
                                setErrPass("");
                                break;
                            case "Please enter correct password!":
                                setErrPass(res.message);
                                setErrLogin("");
                                break;
                            default:
                                setErrLogin("Technical Error");
                        }
                    } else {
                        dispatch(userLoginTrue(true));
                        setSpinLoadVisibility(false);
                        setErrLogin("");
                        setErrPass("");
                        localStorage.setItem("username", res.data.username);
                        localStorage.setItem("UserTokenId", res.data.userId);
                        localStorage.setItem("RefId", res.data.refId);
                        localStorage.setItem("token", res.data.token);
                        dispatch(toasterValue("You have logged in successfully."));
                        if (String(pathname) === "") {
                            navigate(`/profile/${res.data.userId}`);
                        }
                        props.onHide();
                    }
                },
                onError: (error) => {
                    setSpinLoadVisibility(false);
                    setErrLogin("Technical Error");
                }
            }
        );
    };



    return (
        <React.Fragment>
         
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
             
            >

                <div className="rounded-0 ">
                    <div className="sign-in login-in">

                        <div className="row flex-column-reverse flex-md-row">
                            <div className="col-md-6 sign-in-left get-in-touch ">

                                <h3 className="pt-sm-5 pt-2 pb-2 pb-sm-4 mt-sm-2  mt-0 theme-text">Benefits of Login</h3>
                                <ul className="list-style-1 ps-3 theme-text">
                                    <li>You can see members profile</li>
                                    <li>You can apply for matrimonial</li>
                                    <li>You can make a good post for share</li>
                                    <li>You can change your password</li>
                                    <li>You can see your profile</li>
                                    <li>You can edit your profile</li>


                                   
                                </ul>
                            </div>
                            <div className="col-md-6 sign-in-right position-relative ">
                                <button
                                    onClick={() => props.onHide()}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                                <MdLogin className='fs-3 mt-2 mb-3 opacity-75' />
                                <h3 className="pb-4 theme-text">Sign In</h3>
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <div className="col-12 p-0">
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="form-group">
                                                    <input type="email" className="form-control"  {...register('username', { required: true })} placeholder="Enter email" required />
                                                    <small id="emailHelp" className=" form-text text-muted">{errLogin}</small>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group icon-position">
                                                    <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Enter password" required />
                                                    {
                                                        visibility ?
                                                            <span className="icon" onClick={() => pwVisibility(false)}>
                                                                <FaEye />
                                                            </span> :
                                                            <span className="icon" onClick={() => pwVisibility(true)}>
                                                                <FaEyeSlash />
                                                            </span>
                                                    }
                                                    <small id="emailHelp" className="  form-text text-muted">{errPass}</small>
                                                    <p className="resend new mt-2"><Link onClick={()=> props.onHide()} to="resetmail">Forgot Password?</Link></p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="theme-btn select-m">

                                        <button disabled={spinLoadVisibility} className="btn verfy-btn  mb-3" type="submit">
                                            {
                                                spinLoadVisibility === false ?
                                                    <>
                                                        LOGIN <HiOutlineArrowRight />
                                                    </> :
                                                    <div className="spinner-visibility">
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            className='me-2'
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Please Wait...
                                                    </div>
                                            }


                                        </button>
                                        <button onClick={() => openSignUp() } className="btn verfy-btn mt-2 text-uppercase outline" type="button">
                                       Register Here <HiOutlineArrowRight />
                                    </button>
                                    </div>
                                   

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </React.Fragment>
    )
}

export default LoginUser;
